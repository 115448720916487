import { FC, useState } from 'react';
import styles from './cards.module.css';
import FavoriteSvg from '../../../../../shared/components/icons/FavoriteSvg';
import { IconSize } from '../../../../../shared/utils/enums';
import VerifiedSvg from '../../../../../shared/components/icons/VerifiedSvg';
import classNames from 'classnames';
import ThreePointsSvg from '../../../../../shared/components/icons/ThreepointsSvg';
import OrdinateurSvg from '../../../../../shared/components/icons/OrdinateurSvg';
import { AdvancedDropDown } from 'kls-ui';
import logo from '../../../assets/icons/logo-app.svg';
import logoKaraz from '../../../assets/icons/karaz-logo.svg';
import TimeSvg from '../../../../../shared/components/icons/TimeSVG';
import { formatDate } from '../../../utils/helpers/validators.helper';
import { Tooltip } from 'antd';
interface CardProps {
  id: string;
  name: string;
  industry: string;
  role?: string;
  status?: string;
  verification?: string;
  favorite?: boolean;
  imageUrl: string;
  dropDownData?: Array<{
    [key: string]: string | undefined;
  }>;
  guichetName?: string;
  source?: string;
  isEservice?: boolean;
  dateModif?: string;
  isKaraz?: boolean;
  lat: number;
  lng: number;
  isPublic?: boolean;
}

export const OrganizationCard: FC<CardProps & { onSelectDropDown:(selection :{ id: string ;selectedValue: string }) => void }> = (props) => {

  const cardContainerStyle = classNames({
    [styles.cardContainer]: true,
    [styles['portal-cards-css-var']]: true
  });

  const cardRoleStyle = classNames({
    [styles.cardRoleKaraz]: props.isKaraz,
    [styles.cardRole]: !props.isKaraz
  });
  const cardFavoriteStyle = classNames({
    [styles.starF]: props.favorite,
    [styles.starNF]: !props.favorite
  });
  const cardIsPublicStyle = classNames({
    [styles.cardTitleSvg ]: props.isPublic,
    [styles.notPublic]: !props.isPublic
  });
  const { onSelectDropDown } = props;

  const handleSelect = (value: string) => {
    if (onSelectDropDown) {
      onSelectDropDown({id:props.id,selectedValue:value}); 
    } else {
      console.error("onSelectDropDown is not defined");
    }
    console.log(props); 
  };
  const [toggleMoreActions, setToggleMoreActions] = useState<boolean>(false);

  const renderFavoriteIcon = () => {
    return <FavoriteSvg className={cardFavoriteStyle} size={IconSize.PX18} />;
  };

  const renderStatusIcon = () => {
    if(props.isPublic != undefined){return (
      <span className={`material-icon ${cardIsPublicStyle}`}>{props.isPublic ? 'visibility' : 'visibility_off'}</span>
    );}
    return null
  };

  const renderVerificationIcon = () => {
    if (props.verification && !props.isEservice) {
      return (
        <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
      );
    }
    return null;
  };
  const [hovered, setHovered] = useState(false);
  const handleHoverChange = (open: boolean) => {
    setHovered(open);
  };
  const renderDateIcon = () => {
    return props.dateModif ? (  <div className='cursor-pointer'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Tooltip   open={hovered}
      onOpenChange={handleHoverChange} placement="bottom" title={formatDate(props.dateModif)} color='var(--ant-tooltip-background)'>
      <TimeSvg size={IconSize.PX14} className={styles.cardTitleSvg} /></Tooltip></div>
    ) : null;
  };

  const renderDropDown = () => {
    if (toggleMoreActions) {
      return (
        <AdvancedDropDown
          isLunchAction={true}
          style={{ top: '15px', minWidth: '100px', right: '0px',padding:'5px' }}
          closeWhenClickOutside={() => setToggleMoreActions(false)}
          handleChange={(c) => {setToggleMoreActions(false);handleSelect(c.value);}}
          enableSearch={false}
          data={props.dropDownData}
        />
      );
    }
    return null;
  };

  return (
    <div className={cardContainerStyle}>
      <div className={styles.card}>
        <div className={styles.starFContainer}>
          {renderFavoriteIcon()}
          <div
            onClick={() => setToggleMoreActions(!toggleMoreActions)}
            className={styles.threePointsContainer}>
            <ThreePointsSvg className={styles.threePoints} size={IconSize.SM} />
            {renderDropDown()}
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={props.imageUrl || logo} />
        </div>
        <div className={styles.industryContainer}>
          <div>
            {props.isKaraz && (
              <img className={styles.imageKaraz} src={logoKaraz} />
            )}
          </div>
          {props.role && <span className={cardRoleStyle}>{props.role}</span>}
          <span className={styles.industry}>
            <OrdinateurSvg size={IconSize.PX14} />
          </span>
        </div>
      </div>
      <div>
        <div className={styles.cardInfoContainer}>
          <div className={styles.cardnameTime} >
            <span className={styles.cardTitle}>
            {props.name}
            {renderVerificationIcon()}
            </span>
            {renderDateIcon()}

          </div>
          <div>
            {props.status && (
              <>
                <span className={styles.publicCard}>
                  {props.status}
                  {renderStatusIcon()}
                </span>
              </>
            )}
          </div>
        </div>
        <div>
          {props.guichetName && (
            <>
              <span className={styles.cardTitle}>
                <span className={styles.cardProp}>Propriété de </span>
                {props.guichetName}
                {props.isEservice && (
                  <VerifiedSvg
                    size={IconSize.PX14}
                    className={styles.cardTitleSvg}
                  />
                )}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

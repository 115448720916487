import { FC, useRef, useState } from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { MarkerPositionPopUp } from './MarkerPositionPopUp';

interface MarkerProps {
  position: L.LatLngLiteral;
  setPosition:React.Dispatch<React.SetStateAction<L.LatLngLiteral>>;
}
export const MarkerContainer: FC<MarkerProps> = (props) => {
  const [currentPosition, setCurrentPosition] = useState<L.LatLngLiteral>(
    props.position
  );
  const markerRef = useRef<L.Marker<L.LatLngExpression>>(null);
  const customIcon = L.icon({
    iconUrl: '/pictures/marker-logo.png',
    iconSize: [40, 40],
    iconAnchor: [17, 20],
    popupAnchor: [0, -10]
  });

  const updatePosition = (event: L.DragEndEvent) => {
    const marker = event.target as L.Marker;
    setCurrentPosition(marker.getLatLng());
  };

  const handleClickChoisir = () => {
    if (props.setPosition) {
      props.setPosition(currentPosition);
    }
  
    if (markerRef.current) {
      markerRef.current.closePopup();
    }
  };
  

  return (
    <>
      <Marker
        ref={markerRef}
        icon={customIcon}
        position={props.position}
        draggable={true}
        eventHandlers={{
          dragend: updatePosition
        }}>
          <MarkerPositionPopUp
            position={currentPosition}
            setPosition={handleClickChoisir}
          />
      </Marker>
    </>
  );
};

import {OuterContainer,} from "../../../../../shared/components";
import {OrgCreationStep, QAStep, SpaceConfigStep} from "../../../components/ui";
import {
    useCreateWindow,
    useGetOnboardingQuestions,
    usePostOnboardingQA
} from "../../../api";
import {OnboardingGuichetCreationRequest, OnboardingQARequest} from "../../../types";
import {useEffect, useState} from "react";
import classNames from "classnames";
import {OnboardingSteps} from "../../../utils/enums";
import {useGetIndustriesList} from '../../../api'
import {useNavigate} from "react-router-dom";
import {useHandleTranslation} from "../../../../../shared/hooks";


export const OnboardingPage=()=> {
    const [currentStep,setCurrentStep]=useState<OnboardingSteps>(OnboardingSteps.QA);
    const navigate=useNavigate();
    const getOnboardingQuestions=useGetOnboardingQuestions();
    const getIndustriesList =useGetIndustriesList();
    const postOnboardingQA=usePostOnboardingQA();
    const postCreateOrganization=useCreateWindow();
    const { t:tW } = useHandleTranslation("onboarding");

    const handleSubmitQAStep=(data:OnboardingQARequest)=>{
        postOnboardingQA.mutate(data);
    }
    const handleSubmitGuichetCreationStep=(data:OnboardingGuichetCreationRequest)=>{
        postCreateOrganization.mutate(data);
    }
    const onboardingStyle=(step:OnboardingSteps)=>classNames({
        ['show transition-all duration-300']: currentStep === step,
        ['notDisplayV2']: currentStep !== step
    })
    useEffect(() => {
        if(postOnboardingQA.customHandler.isSuccess) setCurrentStep(OnboardingSteps.WORK_SPACE_CONFIG);
    }, [postOnboardingQA.customHandler.isSuccess]);

   const handleValidateSpaceConfigStep=(step:OnboardingSteps|null|undefined)=>{
       if (step) setCurrentStep(step);
       if (step===null) navigate("/");
   }
    useEffect(() => {
       if (postCreateOrganization.customHandler.isSuccess)  navigate("/");
    }, [postCreateOrganization.customHandler.isSuccess]);

return (
    <OuterContainer>
        <QAStep
            data={getOnboardingQuestions.customHandler.data}
            rootClassName={onboardingStyle(OnboardingSteps.QA)}
            onSubmit={handleSubmitQAStep}
            isLoading={{onboardingQuestions:getOnboardingQuestions.isFetching,postAnswers:postOnboardingQA.isPending}}
            isError={{onboardingQuestions:getOnboardingQuestions.customHandler.isError,postAnswers:postOnboardingQA.customHandler.isError}}
            submitError={postOnboardingQA.customHandler.errorMessage}
        />
        <SpaceConfigStep
            onValidate={handleValidateSpaceConfigStep}
            rootClassName={onboardingStyle(OnboardingSteps.WORK_SPACE_CONFIG)}
            onBack={()=>setCurrentStep(OnboardingSteps.QA)}/>
        <OrgCreationStep
            data={{industriesList:getIndustriesList.customHandler.data}}
            isLoading={{postCreateOrganization:postCreateOrganization.isPending,industriesList:getIndustriesList.isLoading}}
            rootClassName={onboardingStyle(OnboardingSteps.GUICHET_CREATION)}
            backButton={{label:tW("orgCreation.onboarding._back_button"),onClick:()=>setCurrentStep(OnboardingSteps.WORK_SPACE_CONFIG)}}
            submitError={postCreateOrganization.customHandler.errorMessage}
            onSubmit={handleSubmitGuichetCreationStep}/>
        {/*<OrgJoiningStep*/}
        {/*    rootClassName={onboardingStyle(OnboardingSteps.GUICHET_JOINING)}*/}
        {/*    onBack={()=>setCurrentStep(OnboardingSteps.GUICHET_CREATION)}/>*/}
    </OuterContainer>
)
}






import { object, string, ref } from 'yup';
import { PasswordStrength } from '../../../../shared/utils/enums';
import { validatePasswordForm } from '../../utils/helpers';

const latinValidationWithoutNumbersAndSpecialCaractersRegex = /^[a-zA-ZÀ-ÿ\s]*$/;
// const latinValidationWithNumbersRegex = /^[a-zA-Z0-9\s]*$/;
// const latinValidationWithNumbersAndSpecialCaractersRegex = /^[a-zA-ZÀ-ÿ0-9\s.!@#$%^&*()-_=+[\]{}|;:'",<>/?]*$/;
// const arabicValidationRegex  = /^[\u0600-\u06FF\u0750-\u077F\uFB50-\uFC3F\uFE70-\uFEFC\s]*$/;

export const UserSignupSchema = object().shape({
  firstName: string().matches(latinValidationWithoutNumbersAndSpecialCaractersRegex,"sign_stepOne_formError_firstName_match").required("sign_stepOne_formError_firstName_required"),
  email: string().email("sign_stepOne_formError_email_invalid").required("sign_stepOne_formError_email_required"),
  lastName: string().matches(latinValidationWithoutNumbersAndSpecialCaractersRegex,"sign_stepOne_formError_firstName_match").required("sign_stepOne_formError_lastName_required"),
  password: string()
    .required("sign_stepOne_formError_password_required")
    .test("",PasswordStrength.WEAK,validatePasswordForm),
  password2: string().oneOf([ref('password'), ""], "sign_stepOne_formError_password2_invalid").required("sign_stepOne_formError_password2_invalid"),
  userName: string().matches( /^[a-zA-ZÀ-ÿ\s._]*$/,"sign_stepOne_formError_userName_match").required("Le Nom d'utilisateur est requis"),
  accetterms:string()
      .test('field3-check', "sign_stepOne_formError_accetterms_invalid", function (value) {
        const { email,userName, firstName, lastName,password,password2} = this.parent;
        if (!(email && userName && firstName &&  lastName && password && password2)) return true
        if (!value) return false;
        if (["false"].includes(value)) return false;

        return true;
      }),

});


import {DesktopIcon, ExploreIcon, GichetIcon, PlusIcon} from "../../../icons";
import {FC, useState} from "react";
import {ConfigCard} from "../cards/ConfigCard";
import styles from "./steps.module.css";
import {Button} from "kls-ui";
import {StepsHeader} from "../layout/StepsHeader";
import classNames from "classnames";
import {OnboardingSteps} from "../../../../utils/enums";
import {SimpleCard} from "../../../../../../shared/components";
import {useHandleTranslation} from "../../../../../../shared/hooks"; // Import i18n

interface SpaceConfigStepProps {
    rootClassName?: string;
    onValidate: (step: OnboardingSteps | undefined | null) => void;
    onBack: () => void;
}

export const SpaceConfigStep: FC<SpaceConfigStepProps> = (props) => {
    const { rootClassName = "" } = props;
    const { t:tO } = useHandleTranslation("window");

    const rootStyle = classNames({
        ["flex flex-col py-2 gap-9"]: true,
    });

    const cardStyle = classNames({
        [rootClassName]: true,
        ["w-1/2"]: true,
    });

    const [selectedCard, setSelectedCard] = useState<OnboardingSteps | null>();
    const handleValidateStep = () => {
        props.onValidate(selectedCard);
    };

    const cardData = [
        {
            icon: <PlusIcon className="size-4 text-emerald-600" />,
            title: tO('spaceConfig.create_new_org'),
            to: OnboardingSteps.GUICHET_CREATION,
            description: tO('spaceConfig.create_new_org_description'),
            onClick: () => {},
        },
        {
            icon: <GichetIcon className="size-4 text-pink-600" />,
            title: tO('spaceConfig.add_existing_org'),
            to: OnboardingSteps.GUICHET_JOINING,
            description: tO('spaceConfig.add_existing_org_description'),
            onClick: () => {},
        },
        {
            icon: <ExploreIcon className="size-4 text-sky-600" />,
            title: tO('spaceConfig.explore_services'),
            to: null,
            description: tO('spaceConfig.explore_services_description'),
            onClick: () => {},
        },
    ];

    return (
        <SimpleCard className={cardStyle}>
            <div className={rootStyle}>
                <StepsHeader
                    icon={<DesktopIcon className="size-12" />}
                    title={tO('spaceConfig.steps_header_title')}
                    description={tO('spaceConfig.steps_header_description')}
                />
                <div className="flex flex-col gap-9">
                    <div className="flex flex-col gap-5 px-3">
                        {cardData.map((element, index) => (
                            <ConfigCard
                                key={index}
                                selected={selectedCard === element.to}
                                {...element}
                                onClick={() => setSelectedCard(element.to)}
                            />
                        ))}
                    </div>
                    <div className={styles.actionButton}>
                        <Button
                            label={tO('spaceConfig.back_button')}
                            disabled={true}
                            color="neutral"
                            size="sm"
                            styling="outline"
                            args={{ onClick: props.onBack }}
                        />
                        <Button
                            label={tO('spaceConfig.validate_button')}
                            disabled={true}
                            color="primary"
                            size="sm"
                            styling="solid"
                            args={{ onClick: handleValidateStep }}
                        />
                    </div>
                </div>
            </div>
        </SimpleCard>
    );
};

import {  useEffect, useState } from "react";
import {  useGetEserviceList } from "../../api";
import StarSvg from "../../../../shared/components/icons/StarSvg";
import PeopleSvg from "../../../../shared/components/icons/PeopleSvg";
import FilterSvg from "../../../../shared/components/icons/FilterSvg";
import { fBhCat, fBsEserv } from "../../mockdata/MockDataFilterBar";
import {  Modal, MultipleAdvancedDropDownList, TextArea } from "kls-ui";
import ListSvg from "../../../../shared/components/icons/ListSvg";
import GridSvg from "../../../../shared/components/icons/GridSvg";
import { FilterBarContentProps } from "../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../components/ui/filter-bar/FilterBarInput";
import { CardGrid } from "../../components/ui/card-grid/CardGrid";
import { FilterBar } from "../../components/ui/filter-bar/FilterBar";
import { EmptyData } from "../../../../shared/components/empty-data";
import { convertToCardProps } from "../../api/eservice/formatteur";
import { Table } from "../../components/ui/table/Table";
import SkeletonGrid from "../../components/ui/card-grid/SkeletonGrid";
import { ROLE_LIST_ESERVICE } from "../../utils/constants/components.constants";
import { ListEserviceRequest } from "../../types/data.types";
import { Eservice } from "../../components/ui/cards/CardComponents/CardEservice";

export const CataloguePage = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [favorite, setFavorite] = useState<boolean>(false);
  const [contribute, setContribute] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<{
    [key: string]: string | undefined;
  }>({ value: 'recent', label: 'Récent' });
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedCardOption, setSelectedCardOption] = useState<{ id: string; selectedValue: string } | null>(null);
  const [allEservicesParams, setAllEservicesParams] =
  useState<ListEserviceRequest>({
    offset: 0,
    limit: 10,
    Fields: 'ALL',
    name: ''
  });
const getEserviceList = useGetEserviceList(allEservicesParams);

const [cardPropsData, setCardPropsData] = useState<Eservice[]>([]);
const isFetching = getEserviceList.isFetching;
const [values, setValues] = useState('');
const selectedValuesArray = values ? values.split(',') : [];
const filteredRoleList = ROLE_LIST_ESERVICE.filter(role => {
  if (selectedValuesArray.includes('admin') && role.value === 'citizen') {
    return false;
  }
  if (selectedValuesArray.includes('citizen') && role.value === 'admin') {
    return false;
  }
  return true;
});

  const fBc: FilterBarContentProps = {
    label: 'Catalogue',
    searchLength: 8,
    buttons: [
      {
        icon: StarSvg,
        label: 'Mes favorites',
        isSelected: favorite,
        handleClick: (favorite) => setFavorite(!favorite)
      },
      {
        icon: PeopleSvg,
        label: 'Où je contribue',
        isSelected: contribute,
        handleClick: (contribute) => setContribute(!contribute)
      }
    ],
  
  };
  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: (showFilters) => {
        setShowFilters(!showFilters);
      },
      icon: FilterSvg,
      label: 'Filtres',
      selected: showFilters
    },

    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: sortBy,
      setSelectedValue: (sortBy) => setSortBy(sortBy)
    },
    onChange: (e) => setSearchValue(e),
    onClear: () => setSearchValue(''),
    onSearch: (searchedValue) => console.log('search click===', searchedValue),
    listButton: {
      handleClick: (showTable) => {
        if (showTable) {
          return;
        }
        setShowGrid(false);
        setShowTable(!showTable);
      },
      icon: ListSvg,
      selected: showTable
    },
    gridButton: {
      handleClick: (showGrid) => {
        if (showGrid) {
          return;
        }
        setShowTable(false);
        setShowGrid(!showGrid);
      },
      icon: GridSvg,
      selected: showGrid
    },
  };
  const onChange = (options: [{ label: string, value: string }]) => {
    console.log(options);
    const value = options.map(option => option.value).join(',');
    setValues(value);
  }


  useEffect(() => {
    setAllEservicesParams((prevData) => ({
      ...prevData,
      name: searchValue,
    }));


  }, [searchValue]);

  useEffect(() => {
    if (getEserviceList.isSuccess) {
      const eserviceData = getEserviceList.data;
      if (eserviceData) {
        console.log('eservicesData data', eserviceData.data.body)

        setCardPropsData(convertToCardProps(eserviceData.data.body));

      }
      return;
    }

  }, [getEserviceList.fetchStatus])


  return (
    <div style={{backgroundColor:'var(--mapping-slate-000)'}} className="flex flex-col gap-7 p-5 h-[100%] rounded-md">
      <FilterBar
        filterBarContent={fBc}
        filterBarInput={fBi}
        filterBarHeader={fBhCat}
        filterBarSortBy={fBsEserv}
      />
     {showGrid && isFetching && <SkeletonGrid />}
     {showGrid && !isFetching && <>{cardPropsData.length > 1 ?
        <CardGrid currentPage={1} totalPages={1} fetchMoreData={(page)=>console.log(page)} items={cardPropsData} type="eservice" onSelectDropDown={setSelectedCardOption} /> :
        <EmptyData title={"Aucun e-service pour l'instant"}
          description={"La liste des e-services créés sera affichée ici"}
        />}</>} 
        
        {showTable && <Table headerItems={{ items: ['', 'Nom', 'Domaine', 'Rôle', 'Status'] }} rows={cardPropsData} />}

      {
  selectedCardOption && selectedCardOption.selectedValue ==='rejoindre'  && 
  <Modal
  onClose={() => { setSelectedCardOption(null) }}
  title={"Demande d’accès"}
  leftBtnArgs={{
    label: 'Annuler',
    args: { onClick: () => setSelectedCardOption(null) },
    color: 'neutral',
    size: 'sm',
    styling: 'outline'
  }}
  rightBtnArgs={{
    label: 'Ajouter',
    args: { onClick: () => { } },
    color: 'primary',
    size: 'sm',
    styling: 'solid'
  }}
  icon={""}
>
  <div className="flex flex-col p-6 gap-3">
  <MultipleAdvancedDropDownList list={filteredRoleList}
              selectedValues={values}
              addNew={null}
              handlerChange={onChange}
              size={3} label={undefined} />
              
    <TextArea size={"xs"}
               args={{
                placeholder: "Ajouter une note...",
                value: 'nomService',
                onChange: () => {  }
              }}
              label="Note" />
     </div>
</Modal>
}
    </div>

  )
}

import KlsLogo from "../../../../shared/assets/icons/kls-3d-logo.svg";
import RepresentativeImge from "../../assets/images/accueil/Representative.svg";
import {PlusIcon} from "@heroicons/react/16/solid";
import UserImage from "../../assets/images/accueil/User.svg";
import CitizenDeveloperImage from "../../assets/images/accueil/CitizenDeveloper.svg";
import ContributorImage from "../../assets/images/accueil/Contributor.svg";
import DigitalDriverImage from "../../assets/images/accueil/DigitalDriver.svg";
import {Cpu, FolderUser, PaintBrushHousehold, Signature} from "@phosphor-icons/react";

const schoolIcon=(<i className="material-icon text-xl text-blue-600 flex justify-center items-center">school</i>)
const KlsLogoIcon=<img src={KlsLogo}/>

export const HOME_PAGE_PROFILES= [
    {
        avatar: {
            title: "Représentant",
            avatar: RepresentativeImge
        },
        title: "Vous êtes Représentant légal de votre organisation ?",
        content: "A travers Karaz vous pouvez créer le guichet unique de votre organisation et y exposer vos processus métier digitalisés grâce au Studio No code / Low code Karaz et en utiisant une large bibliothèque de composants et modèles d’applications prêts à l’emploi.",
        buttonFirst: {
            label: "Créer un guichet unique",
            args:{onClick: () => console.log("Primary button clicked for Card 1")},
            color: "primary",
            styling:"solid",
            size:"sm",
            LeftIcon:<PlusIcon className="size-4"/>
        },
        buttonSecond: {
            label: "Explorer un guichet démo",
            arg:{onClick: () => console.log("Secondary button clicked for Card 1")},
            color: "primary",
            styling:"outline",
            size:"sm"
        },
        checkboxLabel: "Bien compris, ne plus afficher",
        checkStatus: false,
        updateCheckStatus: () => console.log("Check status updated for Card 1"),
    },
    {
        avatar: {
            title: "Usager",
            avatar: UserImage
        },
        title: "Vous êtes Citoyen, Représentant d’une entreprise, en quoi Karaz vous est utile ?",
        content: "Accédez aux guichets uniques de milliers d’organisations pour déposer vos demandes, suivre leur état d’avancement en temps réel et accéder à vos documents signés électroniquement partout et à tout instant",
        buttonFirst: {
            label: "Accéder aux guichets uniques",
            arg:{onClick: () => console.log("Primary button clicked for Card 2")},
            color: "primary",
            styling:"solid",
            size:"sm",
        },
        buttonSecond: {
            label: "Accéder au compte démo",
            arg:{onClick: () => console.log("Secondary button clicked for Card 2")},
            color: "primary",
            styling:"outline",
            size:"sm",
        },
        checkboxLabel: "Bien compris, ne plus afficher",
        checkStatus: true,
        updateCheckStatus: () => console.log("Check status updated for Card 2"),
    },
    {
        avatar: {
            title: "Citizen developer",
            avatar: CitizenDeveloperImage
        },
        title: "Vous êtes un Citizen Developer disposant de compétences métier et/ou techniques ?",
        content: "A travers Karaz vous disposerez d’un puissant environnement No code / Low code pour digitaliser les processus métier de vos clients et vous accéderez à une panoplies d’opportunités projets, tout en bénéficiant de l’appui d’une communauté engagée.",
        buttonFirst: {
            label: "Créer un e-service",
            args:{onClick: () => console.log("Primary button clicked for Card 1")},
            color: "primary",
            styling:"solid",
            size:"sm",
            LeftIcon:<PlusIcon className="size-4"/>
        },
        buttonSecond: {
            label: "Accéder au compte démo",
            arg:{onClick: () => console.log("Secondary button clicked for Card 2")},
            color: "primary",
            styling:"outline",
            size:"sm",
        },
        checkboxLabel: "Bien compris, ne plus afficher",
        checkStatus: true,
        updateCheckStatus: () => console.log("Check status updated for Card 2"),
    },
    {
        avatar: {
            title: "Contributeur",
            avatar: ContributorImage
        },
        title: "Vous Contribuez aux processus métier d’un ou plusieurs guichets ?",
        content: "A travers Karaz vous centraliserez dans un espace unique, l’ensemble des actions qui vous concernent dans les processus métiers des guichets adoptant Karaz. Vous valoriserez vos performances et votre profil au sein d’une communauté vibrante.",
        buttonFirst: {
            label: "Rejoindre un guichet",
            args:{onClick: () => console.log("Primary button clicked for Card 1")},
            color: "primary",
            styling:"solid",
            size:"sm",
            LeftIcon:<PlusIcon className="size-4"/>
        },
        buttonSecond: {
            label: "Explorer un guichet démo",
            arg:{onClick: () => console.log("Secondary button clicked for Card 2")},
            color: "primary",
            styling:"outline",
            size:"sm",
        },
        checkboxLabel: "Bien compris, ne plus afficher",
        checkStatus: true,
        updateCheckStatus: () => console.log("Check status updated for Card 2"),
    },
    {
        avatar: {
            title: "Digital driver",
            avatar: DigitalDriverImage
        },
        title: "Vous accompagnez les usagers à accomplir leurs démarches en ligne ?",
        content: "À travers Karaz, vous pouvez guider les personnes qui ont besoin d'assistance pour déposer leurs demandes, suivre l’avancement de leurs dossiers en temps réel, et les aider à naviguer facilement à travers les processus complexes.",
        buttonFirst: {
            label: "Accéder aux e-services",
            args:{onClick: () => console.log("Primary button clicked for Card 1")},
            color: "primary",
            styling:"solid",
            size:"sm",
            LeftIcon:<PlusIcon className="size-4"/>
        },
        buttonSecond: {
            label: "Explorer un e-shop démo",
            arg:{onClick: () => console.log("Secondary button clicked for Card 2")},
            color: "primary",
            styling:"outline",
            size:"sm",
        },
        checkboxLabel: "Bien compris, ne plus afficher",
        checkStatus: true,
        updateCheckStatus: () => console.log("Check status updated for Card 2"),
    },
]

export const HOME_PAGE_PROCEDURES= [
    {
        icon:<PaintBrushHousehold size={24} />  ,
    heading:"RFC-PCR-BSK-59984/2024",
    status:{title:"En cours", style:"bg-blue-900"},
description:"Réalisation de travaux de réfection d'un immeuble R+5 avec 2 sous sols et des magasins",
    itemsList: [
    {
        icon:<i className="material-icon">corporate_fare</i>,
        description:"Guichet unique Agence de gestion du complexe d’habitation Alanouar"
    },
    {
        icon:<i className="material-icon">category</i>,
        description:"Permis de réfection"
    }
]
},
{
    heading:"ESG-498844/2024",
        icon:<Signature size={24}/>,
    status:{title:"En attente de signature", style:"bg-purple-800"},
    description:"Contrat de travail pour mission de développement logiciel",
        itemsList: [
    {
        icon:<i className="material-icon">corporate_fare</i>,
        description:"Agence de portage informatique"
    },
    {
        icon:<i className="material-icon">category</i>,
        description:"Contrat de travail intelligent"
    }
]
},
{
    icon:<Cpu size={24}/>,
    heading:"XDD-49948/45/4999/RFT",
        status:{title:"Brouillon", style:"bg-stone-800"},
    description:"Demande extrait registre état civil Brahim Hamdani",
        itemsList: [
    {
        icon:<i className="material-icon">corporate_fare</i>,
        description:"CNRST"
    },
    {
        icon:<i className="material-icon">category</i>,
        description:"Appel à projets de recherche"
    }
]
},
{
    heading:"EC-94/2023",
        icon:<FolderUser size={24}/>,
    status:{title:"Clôturé", style:"bg-green-600"},
    description:"Demande extrait registre état civil Brahim Hamdani",
        itemsList:[
    {
        icon:<i className="material-icon">corporate_fare</i>,
        description:"Commune Zouara - Service état civil"
    },
    {
        icon:<i className="material-icon">category</i>,
        description:"Actes état civil"
    }
]
}

]
export const HOME_PAGE_COURSES = [
    {
        price: "1000 MAD",
        rate: { rate: 3, totalRating: 20 },
        title: { text: "Création organisation", icon: schoolIcon },
        heading: {
            title: "Citizen developer:",
            description: "Foundation certificate",
            icon: KlsLogoIcon,
            backgroundColor: "#14B8A6",
        },
        progress: 30,
    },
    {
        price: "1500 MAD",
        rate: { rate: 4, totalRating: 50 },
        title: { text: "Project Management", icon: schoolIcon },
        heading: {
            title: "Certified Manager",
            description: "Advanced course",
            icon: KlsLogoIcon,
            backgroundColor: "#F59E0B",
        },
        progress: 75,
    },
    {
        price: "2000 MAD",
        rate: { rate: 3, totalRating: 30 },
        title: { text: "Data Science", icon: schoolIcon },
        heading: {
            title: "Data Scientist:",
            description: "Certification program",
            icon: KlsLogoIcon,
            backgroundColor: "#3B82F6",
        },
        progress: 60,
    },
    {
        price: "1800 MAD",
        rate: { rate: 4, totalRating: 80 },
        title: { text: "Cyber Security", icon: schoolIcon },
        heading: {
            title: "Security Expert:",
            description: "Advanced certification",
            icon: KlsLogoIcon,
            backgroundColor: "#10B981",
        },
        progress: 100,
    }
];

export const CARD_DROPDOWN_ALL=[
    {
      label: 'Acceder',
      value: 'open',
      icon: 'arrow_outward',
      color: 'var(--blue-600)'
    }
  ];
  export const CARD_DROPDOWN_NO_ROLE=[
    {
      label: 'Rejoindre',
      value: 'rejoindre',
      icon: 'add',
      color: 'var(--red-500)'
    }
  ];
  export const ROLE_LIST_ESERVICE=[
    {
      label: 'Administrateur',
      value: 'admin',
    },
    {
        label: 'Citizen developer',
        value: 'citizen',
      },
       {
        label: 'Collaborateur',
        value: 'collaborateur',
      }
  ];

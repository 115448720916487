import { useEffect, useState } from 'react';
import {
  AccountActivation,
  AccountResendActivation,
  SignUpUser
} from '../../types';
import  SignUpFirstStep from './signup-steps/SignUpFirstStep';
import  SignUpSecondStep from './signup-steps/SignUpSecondStep';
import styles from './signup.module.css';
import classNames from 'classnames';
import { OuterContainer } from '../../../../shared/components';
import {
  usePostSignUpResendActivationCode,
  usePostSignUpActivationAccount,
  usePostSignUpData
} from '../../api';

enum SignUpStep {
  STEP_ONE = 'STEP_ONE',
  STEP_TWO = 'STEP_TWO'
}

const SignUpPage = () => {
  const [currentStep, setCurrentStep] = useState<SignUpStep>(
    SignUpStep.STEP_ONE
  );
  const [transactionToken, setTransactionToken] = useState<string>();
  const [deadline, setDeadline] = useState<string>();
  const postSignUpData = usePostSignUpData();
  const postSignUpActivationAccount = usePostSignUpActivationAccount();
  const postSignUpResendActivationCode = usePostSignUpResendActivationCode();
  const submitSignUpStepOne = (data: SignUpUser) => postSignUpData.mutate(data);
  const submitSignUpStepTwo = (activationCode: string) => {
    const data: AccountActivation = {
      activationId: postSignUpData.customHandler.data?.activationId,
      activationCode
    };
    postSignUpActivationAccount.mutate(data);
  };
  const resendActivationCodeSignupStepTwo = () => {
    const data: AccountResendActivation = { activationId: transactionToken };
    postSignUpResendActivationCode.mutate(data);
    postSignUpActivationAccount.reset();
  };

  useEffect(() => {
    if (postSignUpData.customHandler.isSuccess) {
      setCurrentStep(SignUpStep.STEP_TWO);
      setTransactionToken(postSignUpData.customHandler?.data?.activationId);
      setDeadline(postSignUpData?.customHandler?.data?.expired);
    }
  }, [postSignUpData.customHandler.isSuccess]);

  useEffect(() => {
    if (postSignUpResendActivationCode.customHandler.isSuccess) {
      setTransactionToken(
        postSignUpResendActivationCode.customHandler?.data?.activationId
      );
      setDeadline(postSignUpResendActivationCode?.customHandler?.data?.expired);
    }
  }, [postSignUpResendActivationCode.customHandler.isSuccess]);

  const signupStyles = (step: SignUpStep) =>
    classNames({
      [styles.stepsContainer]: true,
      ['show']: currentStep === step,
      ['notDisplayV2']: currentStep !== step
    });
   console.log("api",postSignUpActivationAccount)
  return (
    <OuterContainer>
      <SignUpFirstStep
        className={signupStyles(SignUpStep.STEP_ONE)}
        onSubmit={submitSignUpStepOne}
        isLoading={postSignUpData.isPending}
        isError={postSignUpData.customHandler.isError}
        submitError={postSignUpData.customHandler.errorMessage}
      />

      <SignUpSecondStep
        className={signupStyles(SignUpStep.STEP_TWO)}
        onSubmit={submitSignUpStepTwo}
        isLoading={{
          accountActivation: postSignUpActivationAccount.isPending,
          resendActivationToken: postSignUpResendActivationCode.isPending
        }}
        isError={{
          accountActivation: postSignUpActivationAccount.customHandler.isError,
          resendActivationToken:
            postSignUpResendActivationCode.customHandler.isError
        }}
        isSuccess={{
          accountActivation:
            postSignUpActivationAccount.customHandler.isSuccess,
          resendActivationToken:
            postSignUpResendActivationCode.customHandler.isSuccess
        }}
        deadline={deadline}
        resendActivationCode={resendActivationCodeSignupStepTwo}
        submitError={postSignUpActivationAccount.customHandler.errorMessage}
      />
    </OuterContainer>
  );
};
export default SignUpPage;

import { FilterBar } from '../../../components/ui/filter-bar/FilterBar';
import { fBh, fBMap } from '../../../mockdata/MockDataFilterBar';
import { useEffect, useState } from 'react';
import { FilterBarContentProps } from '../../../components/ui/filter-bar/FilterBarContent';
import StarSvg from '../../../../../shared/components/icons/StarSvg';
import PeopleSvg from '../../../../../shared/components/icons/PeopleSvg';
import { FilterBarInputProps } from '../../../components/ui/filter-bar/FilterBarInput';
import FilterSvg from '../../../../../shared/components/icons/FilterSvg';
import LocalisationSvg from '../../../../../shared/components/icons/LocalisationSvg';
import GridSvg from '../../../../../shared/components/icons/GridSvg';
import ListSvg from '../../../../../shared/components/icons/ListSvg';
import { Table } from '../../../components/ui/table/Table';
import { Map } from '../../../../../shared/components/map/Map';
import { PageContentContainer } from '../../../layouts';
import { AllGuichetsRequest } from '../../../types/data.types';
import { useGetAllGuichets } from '../../../api';
import { convertToGuichetCard } from '../../../api/window/formatter';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterBarSortByProps } from '../../../components/ui/filter-bar/FilterBarSortBy';
import { CardGrid } from '../../../components/ui/card-grid/CardGrid';
import { Guichet } from '../../../components/ui/cards/CardComponents/CardGuichet';
import { EmptyData } from '../../../../../shared/components';
export const WindowListPage = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [favorite, setFavorite] = useState<boolean>(false);

  const [contribute, setContribute] = useState<boolean>(false);

  const [showFilters, setShowFilters] = useState<boolean>(false);

  const [showGrid, setShowGrid] = useState<boolean>(true);

  const [showTable, setShowTable] = useState<boolean>(false);

  const [showMap, setShowMap] = useState<boolean>(false);

  const [selectedElemnt, setSelectedElement] = useState('');

  const [totalItems, setTotalItems] = useState(0);

  const [sortBy, setSortBy] = useState<{
    value: string;
    label: string;
  }>({ value: 'recent', label: 'Récent' });

  //Search Input Value State
  const [searchValue, setSearchValue] = useState<string>('');

  //Filters States
  const [domaine, setDomaine] = useState<string>('');

  const [verificationGuichet, setVerificationGuichet] = useState<string>('');

  const [role, setRole] = useState<string>('');

  const [statutRole, setStatutRole] = useState<string>('');

  const [page, setPage] = useState<number>(1);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [selectedCardOption, setSelectedCardOption] = useState<{
    id: string;
    selectedValue: string;
  } | null>(null);

  const [guichetsParams, setGuichetParams] = useState<AllGuichetsRequest>({
    numPage: 1,
    pageSize: 10,
    contributeur: false,
    key: '',
    name: '',
    status: '',
    statutRole: '',
    reference: '',
    domainName: '',
    email: '',
    activation: '',
    verification: '',
    tags: '',
    type: '',
    role: '',
    favorite: false,
    industry: '',
    entity: '',
    recent: '',
    Fields: 'ALL'
  });

  const [guichetsValue, setGuichetsValue] = useState<Guichet[]>([]);

  const [position, setPosition] = useState<L.LatLngLiteral>({
    lat: 31.7917,
    lng: -7.0926
  });
  console.log(setPosition,selectedElemnt);
  
  const getGuichets = useGetAllGuichets(guichetsParams);

  useEffect(() => {
    setIsFetching(true);
    setGuichetParams((prevData) => ({
      ...prevData,
      name: searchValue,
      favorite: favorite,
      statutRole: statutRole,
      verification: verificationGuichet,
      domainName: domaine,
      role: role,
      recent: sortBy.value,
      numPage: page,
      contributeur: contribute
    }));
  }, [
    page,
    contribute,
    verificationGuichet,
    statutRole,
    role,
    domaine,
    sortBy,
    searchValue,
    favorite
  ]);
  useEffect(() => {
    if (getGuichets.isFetching) {
      setIsFetching(true);
    }
    if (getGuichets.isSuccess) {
      const guichets = getGuichets.data.body.data;
      const totalItems = getGuichets.data.body.pagination.totalLength;
      if (guichets !== undefined) {
        if (totalItems !== undefined) {
          setTotalItems((prev) => (totalItems !== prev ? totalItems : prev));
        }
        setGuichetsValue((prevData) => {
          return showGrid && page > 1
            ? [...prevData, ...convertToGuichetCard(guichets)]
            : convertToGuichetCard(guichets);
        });
        setIsFetching(false);
      }
      return;
    }
  }, [guichetsParams, getGuichets.status]);

  useEffect(() => {
    if (selectedCardOption?.id) handleNavigateToGuichet(selectedCardOption?.id);
  }, [selectedCardOption]);

  const handleNavigateToGuichet = (guichetId: string) => {
    const currentBasePath = location.pathname.includes('utilisateur')
      ? 'utilisateur'
      : 'guichet';
    navigate(`/${currentBasePath}/guichets/${guichetId}`);
  };

  const fBc: FilterBarContentProps = {
    label: 'Guichets',
    searchLength: totalItems,
    buttons: [
      {
        icon: StarSvg,
        label: 'Mes favorites',
        isSelected: favorite,
        handleClick: (favorite) => {
          if (page > 1) {
            setPage(1);
          }
          setGuichetsValue([]);
          setFavorite(!favorite);
        }
      },
      {
        icon: PeopleSvg,
        label: 'Où je contribue',
        isSelected: contribute,
        handleClick: (contribute) => {
          if (page > 1) {
            setPage(1);
          }
          setGuichetsValue([]);
          setContribute(!contribute);
        }
      }
    ],
    dropDownData: [
      {
        label: 'Créer organisation',
        value: 'new',
        icon: 'add',
        color: 'var(--blue-600)'
      },
      {
        label: 'Rejoindre une organisation',
        value: 'existing',
        icon: 'corporate_fare',
        color: 'var(--pink-600)'
      }
    ],
    dropDownPlaceHolder: 'Nouveau guichet',
    onSelectDropDown: setSelectedElement
  };
  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher...',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: (showFilters) => {
        setShowFilters(!showFilters);
      },
      icon: FilterSvg,
      label: 'Filtres',
      selected: showFilters
    },
    mapButton: {
      handleClick: (showMap) => {
        if (showMap) {
          return;
        }
        setShowGrid(false);
        setShowTable(false);
        setShowMap(!showMap);
      },
      icon: LocalisationSvg,
      selected: showMap
    },
    gridButton: {
      handleClick: (showGrid) => {
        if (showGrid) {
          return;
        }
        setShowMap(false);
        setShowTable(false);
        setShowGrid(!showGrid);
      },
      icon: GridSvg,
      selected: showGrid
    },
    listButton: {
      handleClick: (showTable) => {
        if (showTable) {
          return;
        }
        setShowMap(false);
        setShowGrid(false);
        setShowTable(!showTable);
      },
      icon: ListSvg,
      selected: showTable
    },
    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: sortBy,
      setSelectedValue: (sortBy) => {
        if (page > 1) {
          setPage(1);
        }
        setGuichetsValue([]);
        setSortBy(sortBy);
      }
    },
    onChange: (e) => {
      if (page > 1) {
        setPage(1);
      }
      setGuichetsValue([]);
      setSearchValue(e);
    },
    onClear: () => {
      if (page > 1) {
        setPage(1);
      }
      setGuichetsValue([]);
      setSearchValue('');
    },
    onSearch: (e) => {
      if (page > 1) {
        setPage(1);
      }
      setGuichetsValue([]);
      setSearchValue(e);
    }
  };
  const fBs: FilterBarSortByProps = {
    items: [
      {
        data: [
          {
            label: 'Microsoft',
            value: 'Microsoft'
          },
          {
            label: 'Ribatis',
            value: 'Ribatis'
          }
        ],
        label: 'Organisation(s)',
        placeHolder: 'Organisation',
        selectedValue: verificationGuichet,
        onChange: (e: string) => {
          if (page > 1) {
            setPage(1);
          }

          setGuichetsValue([]);
          setVerificationGuichet(e);
        }
      },
      {
        data: [
          {
            label: 'Education',
            value: 'Education'
          },
          {
            label: 'Services & Informatique',
            value: 'Services & Informatique'
          }
        ],
        label: 'Domaine(s)',
        placeHolder: 'Domaine',
        selectedValue: domaine,
        onChange: (e: string) => {
          if (page > 1) {
            setPage(1);
          }

          setGuichetsValue([]);
          setDomaine(e);
        }
      },
      {
        data: [
          { label: 'Propriétaire', value: 'Propriétaire' },
          { label: 'Administrateur', value: 'Administrateur' },
          { label: 'Développeur citoyen', value: 'Développeur citoyen' },
          { label: 'Utilisateur', value: 'Utilisateur' }
        ],
        label: 'Rôle',
        placeHolder: 'Rôle',
        selectedValue: role,
        onChange: (e: string) => {
          if (page > 1) {
            setPage(1);
          }

          setGuichetsValue([]);
          setRole(e);
        }
      },
      {
        data: [
          {
            label: 'Approuvé',
            value: 'Approuvé'
          },
          {
            label: 'Non Approuvé',
            value: 'Non Approuvé'
          }
        ],
        label: 'Statut Rôle',
        placeHolder: 'Statut Rôle',
        selectedValue: statutRole,
        onChange: (e: string) => {
          if (page > 1) {
            setPage(1);
          }

          setGuichetsValue([]);
          setStatutRole(e);
        }
      }
    ]
  };
  return (
    <PageContentContainer className="flex flex-col gap-7 p-5 h-[100%] rounded-md">
      <FilterBar
        filterBarContent={fBc}
        filterBarInput={fBi}
        filterBarHeader={fBh}
        filterBarSortBy={fBs}
      />

      {/* Grid Loader */}
      {showGrid &&
        (guichetsValue.length > 0 || isFetching ? (
          <CardGrid
            isFetching={isFetching}
            type="guichet"
            totalPages={Math.ceil(totalItems / 10)}
            currentPage={page}
            fetchMoreData={(nextPage: number) => {
              setPage(nextPage);
            }}
            items={guichetsValue}
            onSelectDropDown={setSelectedCardOption}
          />
        ) : (
          <EmptyData title={''} description="Aucun résultat" />
        ))}

      {/* Map Loader */}
      {showMap && isFetching && <div>Loading map...</div>}
      {showMap && !isFetching && (
        <Map
          filtersData={fBMap}
          position={position}
          // markersData={guichetsValue}
        />
      )}

      {/* Table Loader */}
      {showTable && isFetching && <div>Loading table...</div>}
      {showTable && !isFetching && (
        <Table
          headerItems={{
            items: ['', 'Nom', 'Industrie', 'Role', 'Status role']
          }}
          rows={guichetsValue}
        />
      )}
    </PageContentContainer>
  );
};

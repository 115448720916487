import styles from './list.module.css'
import {forwardRef, ForwardRefRenderFunction, useEffect, useState} from "react";
import classNames from "classnames";
import {ListItemType, ListProps} from "../../types";
import {ListItem} from "./list-item/ListItem";




 const ListForwardedRef:ForwardRefRenderFunction<HTMLDivElement,ListProps>=(props,ref)=>{
    const [listElements,setListElements]=useState<ListItemType[]>()
    const{multiple=false,size="sm",className="",preventCheck=false,closeAlways=false}=props
    const [selectedElements,setSelectedElements]=useState<string[]>();
    const close=()=> (props.setOpenStatus(false))
    const single=!multiple;

    const handleSelectElements=(value:string)=>{
        if (isItemSelected(value)){
           if(multiple) HandleSelectExistingMultipleElements(value);
           if (closeAlways) close()
       }
       else {
         if (multiple) handleSelectNonExistingMultipleElements(value);
         if (single) {
             handleSelectNonExistingSingleElement(value);
             close();
         }
       }
    }
    const HandleSelectExistingMultipleElements=(value:string)=>{
        const selectedValues=selectedElements?.filter((item)=>(item!==value));
        setSelectedElements(selectedValues);
    }
    const handleSelectNonExistingMultipleElements=(value:string)=>{
        if (selectedElements)  setSelectedElements([...selectedElements,value])
        else setSelectedElements([value]) ;
    }
    const handleSelectNonExistingSingleElement=(value:string)=>(setSelectedElements([value]));


     const isItemSelected=(value:string)=> (selectedElements && selectedElements.includes(value))

     const handleSelectItemStatus=(value:string)=> {
        if (preventCheck) return false;
       return isItemSelected(value);
    }



     const rootStyles=classNames({
       ["transition-all duration-200"]:true,
       [className]:true,
       [styles.rootContainer]:true,
       [styles["portal-list-css-var"]]:true,
       ["show"]:props.open,
       ["notShow"]:!props.open
   })
    const listContainerStyle=classNames({
        ["max-h-40"]:size==="sm",
        ["max-h-44"]:size==="md",
        ["overflow-y-auto"]:true
    })



     useEffect(()=>{
     if (selectedElements)  props.onChange(selectedElements)
    },[selectedElements])


     useEffect(()=>{
        if (props.list) setListElements(props.list)
     },[props.list])
     return(
        <div className={rootStyles} ref={ref} >
                <div className={listContainerStyle}>
                    {listElements && listElements.map((item, index) =>
                        <ListItem size={size} selected={handleSelectItemStatus(item.value)} key={index} label={item.label}
                                  icon={item.icon} onClick={()=>handleSelectElements(item.value)}/>)}
                </div>
        </div>
    )
}

export const List=forwardRef(ListForwardedRef);
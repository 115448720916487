import { FC } from 'react';
import { FilterBarContent, FilterBarContentProps } from './FilterBarContent';
import { FilterBarHeader, FilterBarHeaderProps } from './FilterBarHeader';
import { FilterBarInput, FilterBarInputProps } from './FilterBarInput';
import { FilterBarSortBy, FilterBarSortByProps } from './FilterBarSortBy';
import classNames from "classnames";

export interface FilterBarProps {
  filterBarHeader?: FilterBarHeaderProps;
  filterBarContent?: FilterBarContentProps;
  filterBarInput: FilterBarInputProps;
  filterBarSortBy: FilterBarSortByProps;
  className?:string,
}
export const FilterBar: FC<FilterBarProps> = (props) => {
 const {className=""} =props;
    const rootStyles=classNames({
     ["flex flex-col gap-4"]:true,
     [className]:true,
 })
  return (
    <div className={rootStyles}>
        {props.filterBarHeader &&
        <FilterBarHeader
        childs={props.filterBarHeader?.childs}
        hasChilds={props.filterBarHeader.hasChilds}
        parent={props.filterBarHeader.parent}
        icon={props.filterBarHeader.icon}
      />
        }
        {props.filterBarContent && <FilterBarContent
        label={props.filterBarContent.label}
        searchLength={props.filterBarContent.searchLength}
        buttons={props.filterBarContent.buttons}
        dropDownData={props.filterBarContent.dropDownData}
        dropDownPlaceHolder={props.filterBarContent.dropDownPlaceHolder}
        onSelectDropDown={props.filterBarContent.onSelectDropDown}
      />}
      <FilterBarInput
        filterBarInputId={props.filterBarInput.filterBarInputId}
        filterBarInputPlaceHolder={
          props.filterBarInput.filterBarInputPlaceHolder
        }
        filterBarInputType={props.filterBarInput.filterBarInputType}
        filterBarInputValue={props.filterBarInput.filterBarInputValue}
        filterButton={props.filterBarInput.filterButton}
        gridButton={props.filterBarInput.gridButton}
        isGridDisplayMode={props.filterBarInput.isGridDisplayMode}
        itemsFilterBarSort={props.filterBarInput.itemsFilterBarSort}
        listButton={props.filterBarInput.listButton}
        mapButton={props.filterBarInput.mapButton}
        onChange={props.filterBarInput.onChange}
        onClear={props.filterBarInput.onClear}
        onSearch={props.filterBarInput.onSearch}
      />
      {props.filterBarInput.filterButton.selected && (
        <FilterBarSortBy items={props.filterBarSortBy.items} />
      )}
    </div>
  );
};

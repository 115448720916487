import { FC, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

export const MarkerControl: FC<{
  setPosition?: React.Dispatch<React.SetStateAction<L.LatLngLiteral>>;
}> = ({ setPosition }) => {
  const map = useMap();
  const markerRef = useRef<L.Marker | null>(null);

  useEffect(() => {
    const markerButton = L.control({ position: 'topleft' });
    markerButton.onAdd = function () {
      const div = L.DomUtil.create(
        'div',
        'leaflet-bar leaflet-control leaflet-control-custom'
      );
      div.innerHTML =
        '<span class="flex justify-center items-center w-full h-full"><svg width="20" height="20" fill="white" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg"><path d="M6.66663 3.00004V5.00004H8.66663V6.33337H6.66663V8.33337H5.33329V6.33337H3.33329V5.00004H5.33329V3.00004H6.66663ZM9.99996 5.80004C9.99996 3.38004 8.23329 1.66671 5.99996 1.66671C3.76663 1.66671 1.99996 3.38004 1.99996 5.80004C1.99996 7.36004 3.29996 9.42671 5.99996 11.8934C8.69996 9.42671 9.99996 7.36004 9.99996 5.80004ZM5.99996 0.333374C8.79996 0.333374 11.3333 2.48004 11.3333 5.80004C11.3333 8.01337 9.55329 10.6334 5.99996 13.6667C2.44663 10.6334 0.666626 8.01337 0.666626 5.80004C0.666626 2.48004 3.19996 0.333374 5.99996 0.333374Z" /></svg></span>'; // Icon for the button
      div.style.backgroundColor = 'var(--blue-600)';
      div.style.border = '1px solid var(--blue-500)';
      div.style.width = '40px';
      div.style.height = '40px';
      div.style.borderRadius = '8px';
      div.style.cursor = 'pointer';

      div.onmouseover = function () {
        div.style.border = '1px solid var(--blue-600)';
        div.style.transform = 'scale(1.05)';
      };

      div.onmouseout = function () {
        div.style.border = '1px solid var(--blue-600)';
        div.style.transform = 'scale(1)';
      };

      div.onclick = function () {
        if (markerRef.current) {
          map.removeLayer(markerRef.current);
        }
        const customIcon = L.icon({
          iconUrl: '/pictures/marker-logo.png',
          iconSize: [32, 32],
          iconAnchor: [16, 16],
          popupAnchor: [0, -8]
        });
        const center = map.getCenter();
        const newMarker = L.marker(center, {
          draggable: true,
          icon: customIcon
        }).addTo(map);

        if (setPosition) {
          setPosition({ lat: center.lat, lng: center.lng });
        }
        newMarker.on('dragend', (event) => {
          const marker = event.target;
          const position = marker.getLatLng();
          if (setPosition) {
            setPosition({ lat: position.lat, lng: position.lng });
          }
        });
        markerRef.current = newMarker;
      };

      return div;
    };

    if (markerButton) {
      markerButton.addTo(map);
    }

    return () => {
      map.removeControl(markerButton);
    };
  }, [map, setPosition]);

  return null;
};

import { AdvancedDropDownList } from 'kls-ui';
import { FC } from 'react';
import styles from './filterBar.module.css';
export interface FilterBarItemSortByProps {
  label: string;
  data: Array<{
    [key: string]: string | undefined;
  }>;
  placeHolder: string;
  selectedValue: string;
  onChange: (selectedValue: string) => void;
}

export interface FilterBarSortByProps {
  items: FilterBarItemSortByProps[];
}

export const FilterBarSortBy: FC<FilterBarSortByProps> = (props) => {
  return (
    <div className={styles.filterSortBy}>
      {props.items.map((item: FilterBarItemSortByProps, index: number) => (
        <div key={index} className={styles.filterSortItemContainer}>
          <div className={styles.filterSortItemLabel}>{item.label}</div>
          <AdvancedDropDownList
            list={item.data}
            withoutError
            key={index}
            size={1}
            selectedValue={item.selectedValue}
            placeholder={item.placeHolder}
            handlerChange={(e) =>
              item.onChange((e.target as HTMLInputElement).value)
            }
          />
        </div>
      ))}
    </div>
  );
};

import { useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { getEservicesList, postCreateEservice } from "./api";
import { EserviceCreationRequest, ListEserviceRequest } from "../../types/data.types";
import { useHandleStandardResult } from "../../../../shared/hooks";



export const useGetEserviceList = (data:ListEserviceRequest) => {
    const reactQuery = useQuery({
      queryKey: ["list-services" , JSON.stringify(data)],
      queryFn: () => getEservicesList(data),
      retry: 0,
      staleTime:0
    });

   
    const customHandler = useHandleStandardResult(reactQuery);
    return { ...reactQuery, customHandler };
  };

  export const useCreateEservice=()=>{
    const queryClient = useQueryClient(); 

    const mutationQuery= useMutation({
        mutationFn:  (data:EserviceCreationRequest) => postCreateEservice(data),
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['list-services'] });
        },
    });
    const customHandler = useHandleStandardResult(mutationQuery);
    return { ...mutationQuery,customHandler };
}
import { FC, useState } from 'react';
import { BaseSVGIconProps } from '../../../../../shared/types';
import styles from './filterBar.module.css';
import ArrowSvg from '../../../../../shared/components/icons/ArrowSvg';
import { AdvancedDropDown } from 'kls-ui';
interface ButtonFilterProps {
  label: string;
  icon: FC<BaseSVGIconProps>;
  handleClick: (b: boolean) => void;
  isSelected: boolean;
}

export interface FilterBarContentProps {
  label: string;
  searchLength: number;
  buttons: ButtonFilterProps[];
  dropDownPlaceHolder?: string;
  dropDownData ?: Array<{
    [key: string]: string | undefined;
  }>;
  onSelectDropDown ?: (selectedValue: string) => void;
}

export const FilterBarContent: FC<FilterBarContentProps> = (props) => {
  const [toggleBtnGuichet, setToggleBtnGuichet] = useState<boolean>(false);
  return (
    <div className={styles.filterContent}>
      <div className="flex gap-4">
        <div className="flex gap-2 items-center">
          <span>{props.label}</span>
          <span className={styles.filterContentLength}>
            {props.searchLength}
          </span>
        </div>
        <div className="flex gap-4 justify-center">
          {props.buttons.map((button, index) => (
            <button
              onClick={() => button.handleClick(button.isSelected)}
              className={
                button.isSelected
                  ? styles.selectedFilterContentFltrBtn
                  : styles.filterContentFltrBtn
              }
              key={index}>
              <button.icon />
              {button.label}
            </button>
          ))}
        </div>
      </div>
    {props.dropDownData && props.dropDownPlaceHolder && props.onSelectDropDown &&  <div className="relative flex items-center">
        <button
          onClick={() => setToggleBtnGuichet(!toggleBtnGuichet)}
          className={styles.filterContentFltrBtnGuichet}>
          {props.dropDownPlaceHolder}
          <ArrowSvg />
        </button>
        {toggleBtnGuichet && (
          <AdvancedDropDown
            isLunchAction={true}
            maxItemsPerPage={5}
            style={{ top: '48px', minWidth: '200px', left: '-55px' }}
            closeWhenClickOutside={() => {
              setToggleBtnGuichet(!toggleBtnGuichet);
            }}
            handleChange={(c) => {
              setToggleBtnGuichet(!toggleBtnGuichet);
              if (props.onSelectDropDown) {
                props.onSelectDropDown(c.value);
              }            }}
            enableSearch={false}
            data={props.dropDownData}
            ></AdvancedDropDown>
        )}
      </div>
}
    </div>
  );
};

import {FC} from "react";
import classNames from "classnames";
import styles from "./cards.module.css"
import {Plus} from "@phosphor-icons/react";
interface OrganizationAddCardProps{
  onClick:()=>void
    className?:string
}

export const OrganizationAddCard:FC<OrganizationAddCardProps>=(props)=>{
    const {className=""}=props
    const rootStyle=classNames({

        [styles["portal-cards-css-var"]]:true,
        [styles.addCardContainer]:true,
        [className]:true
    })

    return (<div className={rootStyle} onClick={props.onClick}>
        <Plus size={32} className={styles.addCardIcon} />
    </div>)
}
import { AbstractData, ApiJaxRsResponse, GenericResponseWrapper } from "../../../../shared/types";
import { HttpMethods } from "../../../../shared/utils/enums";
import { getUserProfile } from "../../../../shared/utils/helpers";
import { mapToUrlSearchParams } from "../../../../shared/utils/mapper";
import { EserviceCreationRequest, EserviceData, ListEserviceRequest } from "../../types/data.types";
import { ESERVICE_CREATION_API_PATH, ESERVICE_LIST_API_PATH } from "./apiPath";

export const getEservicesList = (data:ListEserviceRequest): Promise<GenericResponseWrapper<EserviceData[]>> => {
    return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_LIST_API_PATH, HttpMethods.POST);
  };

  export const postCreateEservice=(data:EserviceCreationRequest):Promise<GenericResponseWrapper<null>> =>{
    return fetchGenericJaxRsRequestFromFormData(data,ESERVICE_CREATION_API_PATH,HttpMethods.POST);
}

const fetchGenericJaxRsRequestFromFormData=async <T extends AbstractData> (data:T|undefined,apiPath:ApiJaxRsResponse,method:HttpMethods)=>{
    let formData: URLSearchParams=new URLSearchParams();
    if (data)  formData= mapToUrlSearchParams(data);
    const userId= getUserProfile()?.userId ?? ""
    formData.append("userId",userId);
    Object
        .keys(apiPath)
        .forEach((ApiPathKey)=>{formData.append(ApiPathKey,apiPath[ApiPathKey as keyof ApiJaxRsResponse]);});

    return fetch(apiPath.path,{
        method,
        body: formData,
    }).then((result)=>result.json())
}
import {Personae} from "../../components/ui";
import {HOME_PAGE_PROFILES} from "../../utils/constants";

import {
    DocumentsWidget,
    FavoriteEserviceWidget,
    MyEparapheurWidget,
    ProceduresWidget,
    TrainingProgramWidget
} from "../widgets";


export const AccueilPage = () => {

  return (
      <div className="flex flex-col gap-3">
          <Personae personae={HOME_PAGE_PROFILES}/>
          <div className="flex gap-3">
             <ProceduresWidget/>
             <MyEparapheurWidget/>
          </div>
           <DocumentsWidget/>
          <div className="flex gap-2">
            <FavoriteEserviceWidget/>
            <FavoriteEserviceWidget/>
          </div>
          <TrainingProgramWidget/>
      </div>
  )
}

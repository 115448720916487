import { FC, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

export const HomeControl: FC<{
  defaultCenter: L.LatLngExpression;
  defaultZoom: number;
}> = ({ defaultCenter, defaultZoom }) => {
  const map = useMap();

  useEffect(() => {
    const homeButton = L.control({ position: 'topleft' });
    homeButton.onAdd = function () {
      const div = L.DomUtil.create(
        'div',
        'leaflet-bar leaflet-control leaflet-control-custom'
      );
      div.innerHTML =
        '<span class="flex justify-center items-center w-full h-full"><svg width="20" height="20" viewBox="0 0 14 12" fill="var(--mapping-slate-500)"  xmlns="http://www.w3.org/2000/svg"><path d="M7.00004 2.12671L10.3334 5.12671V10.3334H9.00004V6.33337H5.00004V10.3334H3.66671V5.12671L7.00004 2.12671ZM7.00004 0.333374L0.333374 6.33337H2.33337V11.6667H6.33337V7.66671H7.66671V11.6667H11.6667V6.33337H13.6667L7.00004 0.333374Z" /></svg></span>'; // Home icon
      div.style.backgroundColor = 'var(--mapping-slate-000)';
      div.style.border = '1px solid var(--mapping-slate-200)';
      div.style.width = '40px';
      div.style.height = '40px';
      div.style.borderRadius = '8px';
      div.style.cursor = 'pointer';
      // Add hover effect
      div.onmouseover = function () {
        div.style.border = '1px solid var(--mapping-slate-300)'; // Change border on hover
        div.style.transform = 'scale(1.05)';
      };

      div.onmouseout = function () {
        div.style.border = '1px solid var(--mapping-slate-200)'; // Reset border when not hovered
        div.style.transform = 'scale(1)';
      };

      div.onclick = function () {
        map.setView(defaultCenter, defaultZoom); // Reset map to default view
      };

      return div;
    };

    if (homeButton) {
      homeButton.addTo(map);
    }

    return () => {
      map.removeControl(homeButton);
    };
  }, [map, defaultCenter, defaultZoom]);

  return null;
};

import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './map.css';
import { FC } from 'react';
import { MarkerClusterContainer } from './mapComponents/MarkerClusterContainer';
import { MarkerContainer } from './mapComponents/MarkerContainer';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import { LocateControl } from './mapComponents/LocateControl';
import { FilterControl } from './mapComponents/FilterControl';
import { HomeControl } from './mapComponents/HomeControl';
import { FilterBarSortByProps } from '../../../modules/portal/components/ui/filter-bar/FilterBarSortBy';
import { MarkerControl } from './mapComponents/MarkerControl';
import { Guichet } from '../../../modules/portal/components/ui/cards/CardComponents/CardGuichet';

interface MapProps {
  position: L.LatLngLiteral;
  onChoosePosition?: React.Dispatch<React.SetStateAction<L.LatLngLiteral>>;
  markersData?: Guichet[];
  filtersData?: FilterBarSortByProps;
}

export const Map: FC<MapProps> = (props) => {
  return (
    <MapContainer
      className="w-full h-[500px] z-0 rounded-md fullscreen-css-var"
      center={[props.position.lat, props.position.lng]}
      zoom={5}
      maxZoom={17}
      zoomControl={false}>
      <ZoomControl position="bottomright" />
      <FullscreenControl position="topright" />
      {props.filtersData && <FilterControl items={props.filtersData.items} />}
      <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}" />

      {props.onChoosePosition && (
        <>
          <MarkerContainer
            position={props.position}
            setPosition={props.onChoosePosition}
          />
          <LocateControl setPosition={props.onChoosePosition} />

          <MarkerControl />
        </>
      )}

      {props.markersData && props.markersData.length > 0 && (
        <>
          <MarkerClusterContainer markersData={props.markersData} />
          <LocateControl />
        </>
      )}
      <HomeControl
        defaultCenter={[props.position.lat, props.position.lng]}
        defaultZoom={5}
      />
    </MapContainer>
  );
};

import { ChangeEvent, useEffect, useState } from "react";
import FilterSvg from "../../../../shared/components/icons/FilterSvg";
import PeopleSvg from "../../../../shared/components/icons/PeopleSvg";
import StarSvg from "../../../../shared/components/icons/StarSvg";
import { FilterBar } from "../../components/ui/filter-bar/FilterBar"
import { FilterBarContentProps } from "../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../components/ui/filter-bar/FilterBarInput";
import { fBhEsrv, fBsEserv } from "../../mockdata/MockDataFilterBar";
import ListSvg from "../../../../shared/components/icons/ListSvg";
import GridSvg from "../../../../shared/components/icons/GridSvg";
import { useCreateEservice, useGetEserviceList } from "../../api";
import { AdvancedDropDownList, Input, Modal, MultipleAdvancedDropDownList, TextArea } from "kls-ui";
import { MAX_CARACTER_NAME } from "../../utils/constants/helpers.constants";
import { cleanAndFormatNameResource } from "../../utils/helpers/validators.helper";
import { CardGrid } from "../../components/ui/card-grid/CardGrid";
import { PageContentContainer } from "../../layouts";
import { EmptyData } from "../../../../shared/components/empty-data";
import { ROLE_LIST_ESERVICE } from "../../utils/constants/components.constants";
import { convertToCardProps } from "../../api/eservice/formatteur";
import { Table } from "../../components/ui/table/Table";
import { useParams } from "react-router-dom";
import {  ListEserviceRequest } from "../../types/data.types";
import SkeletonGrid from "../../components/ui/card-grid/SkeletonGrid";
import { Eservice } from "../../components/ui/cards/CardComponents/CardEservice";

export const ApplicationsPage = () => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [favorite, setFavorite] = useState<boolean>(false);
  const [contribute, setContribute] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<{
    [key: string]: string | undefined;
  }>({ value: 'recent', label: 'Récent' });
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [selectedElemnt, setSelectedElement] = useState("");
  const [selectedCardOption, setSelectedCardOption] = useState<{ id: string; selectedValue: string } | null>(null);

  const [errorMessageCrea, setErrorMessageCrea] = useState('');
  const [nomService, setNomService] = useState('');

  const [allEservicesParams, setAllEservicesParams] =
    useState<ListEserviceRequest>({
      offset: 0,
      limit: 10,
      Fields: 'ALL',
      name: ''
    });
  const getEserviceList = useGetEserviceList(allEservicesParams);

  const [cardPropsData, setCardPropsData] = useState<Eservice[]>([]);
  const isFetching = getEserviceList.isFetching;
  const [values, setValues] = useState('');
  const selectedValuesArray = values ? values.split(',') : [];
  const filteredRoleList = ROLE_LIST_ESERVICE.filter(role => {
    if (selectedValuesArray.includes('admin') && role.value === 'citizen') {
      return false;
    }
    if (selectedValuesArray.includes('citizen') && role.value === 'admin') {
      return false;
    }
    return true;
  });
  const { guichetId } = useParams<{ guichetId: string }>();
  const postCreateEservice = useCreateEservice();
 
  
  const fBc: FilterBarContentProps = {
    label: 'e-Services',
    searchLength: 8,
    buttons: [
      {
        icon: StarSvg,
        label: 'Mes favorites',
        isSelected: favorite,
        handleClick: (favorite) => setFavorite(!favorite)
      },
      {
        icon: PeopleSvg,
        label: 'Où je contribue',
        isSelected: contribute,
        handleClick: (contribute) => setContribute(!contribute)
      }
    ],
    dropDownData: [
      {
        label: 'À partir de zéro',
        value: 'scratch',
        icon: 'web_asset',
        color: 'var(--blue-600)'
      },
      {
        label: 'À partir du catalogue',
        value: 'catalogue',
        icon: 'grid_view',
        color: 'var(--violet-600)'
      },
      {
        label: `À partir d'excel`,
        value: 'excel',
        icon: 'table_chart',
        color: 'var(--emerald-600)'
      },
      {
        label: 'Via l’assistant',
        value: 'assistant',
        icon: 'corporate_fare',
        color: 'var(--orange-600)'
      }
    ],
    dropDownPlaceHolder: 'Nouveau e-service',
    onSelectDropDown: setSelectedElement
  };
  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: (showFilters) => {
        setShowFilters(!showFilters);
      },
      icon: FilterSvg,
      label: 'Filtres',
      selected: showFilters
    },

    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: sortBy,
      setSelectedValue: (sortBy) => setSortBy(sortBy)
    },
    onChange: (e) => setSearchValue(e),
    onClear: () => setSearchValue(''),
    onSearch: (searchedValue) => console.log('search click===', searchedValue),
    listButton: {
      handleClick: (showTable) => {
        if (showTable) {
          return;
        }
        setShowGrid(false);
        setShowTable(!showTable);
      },
      icon: ListSvg,
      selected: showTable
    },
    gridButton: {
      handleClick: (showGrid) => {
        if (showGrid) {
          return;
        }
        setShowTable(false);
        setShowGrid(!showGrid);
      },
      icon: GridSvg,
      selected: showGrid
    },
  };
  const handlecreation = () => {
    if (!nomService || nomService.trim().length == 0) {
      setErrorMessageCrea("Ce champ est requis");
      return;
    }
    if (nomService.length > 0) {
      if (guichetId) {
        postCreateEservice.mutate({
          idGuichet: guichetId,
          nameEservice: nomService,
        });
      } else {
        console.error('guichetId is not available');
      }
      setSelectedElement('');
    }
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setErrorMessageCrea('');
    const value = e.target.value;
    if (value?.length <= MAX_CARACTER_NAME) {
      setNomService(value);
    }
  }

  const onChange = (options: [{ label: string, value: string }]) => {
    console.log(options);
    const value = options.map(option => option.value).join(',');
    setValues(value);
  }

  useEffect(() => {
    setAllEservicesParams((prevData) => ({
      ...prevData,
      name: searchValue,
    }));


  }, [searchValue]);

  useEffect(() => {
    if (getEserviceList.isSuccess) {
      const eserviceData = getEserviceList.data;
      if (eserviceData) {
        console.log('eservicesData data', eserviceData.data.body)

        setCardPropsData(convertToCardProps(eserviceData.data.body));

      }
      return;
    }

  }, [getEserviceList.fetchStatus])
  

  return (
    <PageContentContainer className="flex flex-col gap-7 p-5 !h-[100%]">
      <FilterBar
        filterBarContent={fBc}
        filterBarInput={fBi}
        filterBarHeader={fBhEsrv}
        filterBarSortBy={fBsEserv}
      />
      {showGrid && isFetching && <SkeletonGrid />}
      {showGrid && !isFetching && <>{cardPropsData.length > 1 ?
        <CardGrid type="eservice" items={cardPropsData} onSelectDropDown={setSelectedCardOption} 
        fetchMoreData={()=>{}} currentPage={1} totalPages={1} /> :
        <EmptyData title={"Aucun e-service pour l'instant"}
          description={"La liste des e-services créés sera affichée ici"}
        />}</>}

      {showTable && <Table headerItems={{ items: ['', 'Nom', 'Domaine', 'Rôle', 'Status'] }} rows={cardPropsData} />}


      {selectedElemnt === 'scratch' && <Modal
        onClose={() => { setSelectedElement('') }}
        title={"Nouveau e-service"}
        leftBtnArgs={{
          label: 'Annuler',
          args: { onClick: () => setSelectedElement("") },
          color: 'neutral',
          size: 'sm',
          styling: 'outline'
        }}
        rightBtnArgs={{
          label: 'Ajouter',
          args: { onClick: () => { handlecreation() } },
          color: 'primary',
          size: 'sm',
          styling: 'solid'
        }}
        icon={""}
      >
        <div className="flex flex-col p-6 gap-3">
          <Input
            errorMessage={errorMessageCrea}
            size={"sm"}
            label="Nom de e-service"
            helpText={`ID :${cleanAndFormatNameResource(nomService)}`}
            args={{
              placeholder: "Entrez le nom de votre e-service",
              value: nomService,
              onChange: (e) => { handleNameChange(e) }
            }}
            charCounter={MAX_CARACTER_NAME} />
          <div style={{ color: 'var(--mapping-slate-800)', fontSize: 'var(--text-14)' }}>Guichet</div>
          <AdvancedDropDownList
            list={[]}
            size={3}
            selectedValue={''}
            placeholder={''}
            disabled={true}
          />
        </div>
      </Modal>}

      {
        selectedCardOption && selectedCardOption.selectedValue === 'rejoindre' &&
        <Modal
          onClose={() => { setSelectedCardOption(null) }}
          title={"Demande d’accès"}
          leftBtnArgs={{
            label: 'Annuler',
            args: { onClick: () => setSelectedCardOption(null) },
            color: 'neutral',
            size: 'sm',
            styling: 'outline'
          }}
          rightBtnArgs={{
            label: 'Ajouter',
            args: { onClick: () => { } },
            color: 'primary',
            size: 'sm',
            styling: 'solid'
          }}
          icon={""}
        >
          <div className="flex flex-col p-6 gap-3">
            <div style={{ color: 'var(--mapping-slate-800)', fontSize: 'var(--text-14)' }}>Rôle</div>
            <MultipleAdvancedDropDownList list={filteredRoleList}
              selectedValues={values}
              addNew={null}
              handlerChange={onChange}
              size={3} label={undefined} />
            <TextArea size={"sm"}
              args={{
                placeholder: "Ajouter une note...",
                value: nomService,
                onChange: (e) => { handleNameChange(e) }
              }}
              label="Note" />
          </div>
        </Modal>
      }
    </PageContentContainer>

  )
}

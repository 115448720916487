import {useMutation, useQuery} from "@tanstack/react-query";
import {
    getIndustriesList,
    getOnboardingQuestions,
    postOnboardingQA
} from "./api";
import {OnboardingQARequest} from "../../types";
import {useHandleGenericResponse, useHandleStandardResult} from "../../../../shared/hooks";


export const useGetOnboardingQuestions=()=>{
    const reactQuery= useQuery({
        queryKey:["onboarding-questions"],
        queryFn:  () => getOnboardingQuestions(),
    });
    const customHandler = useHandleGenericResponse(reactQuery);
    return { ...reactQuery,customHandler };
}
export const useGetIndustriesList=()=>{
    const reactQuery= useQuery({
        queryKey:["dynamic-list-industries"],
        queryFn:  () => getIndustriesList(),

    });
    const customHandler = useHandleGenericResponse(reactQuery);
    return { ...reactQuery,customHandler };
}

export const usePostOnboardingQA=()=>{
    const mutationQuery= useMutation({
        mutationFn:  (data:OnboardingQARequest) => postOnboardingQA(data),
    });
    const customHandler = useHandleStandardResult(mutationQuery);
    return { ...mutationQuery,customHandler };
}


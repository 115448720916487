import { FC, MouseEventHandler } from 'react';
import { Popup } from 'react-leaflet';
import { MarkerPositionPopUpContent } from './MarkerPositionPopUpContent';
export interface MarkerPositionPopUpProps {
  position: L.LatLngLiteral;
  setPosition: MouseEventHandler<HTMLSpanElement>;
}
export const MarkerPositionPopUp: FC<MarkerPositionPopUpProps> = ({
  position,
  setPosition
}) => {
  return (
    <Popup>
      <MarkerPositionPopUpContent position={position} setPosition={setPosition}/>
    </Popup>
  );
};

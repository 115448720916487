import { FC } from 'react';
import { BaseSVGIconProps } from '../../../../../shared/types';
import styles from './buttons.module.css';
import classNames from 'classnames';
export interface FilterBarButtonProps {
  label?: string;
  icon: FC<BaseSVGIconProps>;
  handleClick: (b:boolean) => void;
  selected: boolean;
}
const filterBarStyle = (props: FilterBarButtonProps) =>
  classNames({
    [styles.filterBarButton]: !props.selected,
    [styles.selectedFilterBarButton]: props.selected,
  });
  const filterBarIconStyle = (props: FilterBarButtonProps) =>
    classNames({
      [styles.filterBarButtonIcon]: !props.selected,
      [styles.selectedFilterBarButtonIcon]: props.selected,
    });
export const FilterBarButton: FC<FilterBarButtonProps> = (props) => {
  return (
    <button className={filterBarStyle(props)} onClick={()=>props.handleClick(props.selected)}>
      {props.label && <span>{props.label}</span>}
      <props.icon className={filterBarIconStyle(props)} />
    </button>
  );
};

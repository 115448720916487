import styles from './document-upload.module.css';
import classNames from 'classnames';
import { DragEvent, ChangeEvent, useRef } from 'react';

const ALLOWED_FORMATS = ['application/pdf', 'image/png', 'image/jpeg'];

interface DocumentUploadProps {
    onFilesUploaded: (files: File[]) => void;
}

export const DocumentUpload = ({ onFilesUploaded }: DocumentUploadProps) => {
    const rootStyles = classNames({
        [styles.rootContainer]: true,
    });
    const inputRef = useRef<HTMLInputElement>(null);

    const handleDragOver = (e: DragEvent<HTMLDivElement>) => e.preventDefault();

    const handleUploaderChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) handleUploadedFiles(files);
        if (inputRef.current) inputRef.current.value = '';
    };

    const handleUploadedFiles = (files: FileList | null) => {
        if (!files) return;
        const validFiles = Array.from(files).filter(file => ALLOWED_FORMATS.includes(file.type));
        if (validFiles.length > 0) {
            onFilesUploaded(validFiles); 
        }
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleUploadedFiles(files);
    };

    const handleClick = () => {
        if (inputRef.current) inputRef.current.click();
    };

    return (
        <div className={`${rootStyles} ${styles['document-upload-css-var']}`}>
            <div className={styles.uploadContainer} onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleClick}>
                <input ref={inputRef} className="hidden" accept=".jpg, .jpeg, .png, .pdf" type="file" onChange={handleUploaderChange} multiple />
                <div className={styles.uploadContent}>
                    <i className={`material-icon ${styles.uploadIcon}`}>file_upload</i>
                    <div className={styles.uploadText}>
                        <span className={styles.uploadTitle}>Faites glisser ou déposez vos documents ici</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

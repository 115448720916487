import {GenericResponse, GenericResponseWrapper} from "../../../../shared/types";
import { PageResponse } from "../../../../shared/types/api.type";
import { HttpMethods } from "../../../../shared/utils/enums";
import {
    fetchGenericJaxRsRequestFromFormData,
    fetchGenericJaxRsRequestFromJson,
} from "../../../../shared/utils/helpers";
import {
    AllGuichetsRequest,
    AllGuichetsResponse,
    OnboardingGuichetCreationRequest, OnboardingGuichetCreationResponse, OnboardingJoinGuichetRequest,
    UserGuichetsResponse
} from "../../types/data.types";
import { ALL_WINDOW_API_PATH, USER_WINDOW_API_PATH,JOIN_WINDOW_API_PATH ,SUBMIT_WINDOW_CREATION_API_PATH} from "./apiPath";

export const getAllGuichets = (
    data: AllGuichetsRequest
  ): Promise<GenericResponse<PageResponse<AllGuichetsResponse[]>>> => {
    return fetchGenericJaxRsRequestFromFormData(
      data,
      ALL_WINDOW_API_PATH,
      HttpMethods.POST
    );
  };
export const getUserGuichets=(data:AllGuichetsRequest):Promise<UserGuichetsResponse> =>{
    return fetchGenericJaxRsRequestFromFormData(data,USER_WINDOW_API_PATH,HttpMethods.POST);
}
export const addFavoriteGuichet=(data:AllGuichetsRequest):Promise<GenericResponseWrapper<AllGuichetsResponse>> =>{
    return fetchGenericJaxRsRequestFromFormData(data,ALL_WINDOW_API_PATH,HttpMethods.POST);
}
export const postCreateWindow=(data:OnboardingGuichetCreationRequest):Promise<GenericResponseWrapper<OnboardingGuichetCreationResponse>> =>{
    return fetchGenericJaxRsRequestFromFormData(data,SUBMIT_WINDOW_CREATION_API_PATH,HttpMethods.POST);
}
export const postJoinWindow=(data:OnboardingJoinGuichetRequest):Promise<GenericResponseWrapper<null>> =>{
    return fetchGenericJaxRsRequestFromJson(data,JOIN_WINDOW_API_PATH,HttpMethods.POST);
}
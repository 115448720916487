import classNames from 'classnames';
import styles from './cards.module.css';
import { FC, ReactNode } from 'react';
interface CardProps {
  childrenCard: ReactNode;
  childrenInfo: ReactNode;
  onDbClick?:()=>void;
}
export const Card: FC<CardProps> = ({ childrenCard,childrenInfo,onDbClick }) => {
  const cardContainerStyle = classNames({
    [styles.cardContainer]: true,
    [styles['portal-cards-css-var']]: true
  });
  return (
    <div onDoubleClick={onDbClick} className={cardContainerStyle}>
      <div className={styles.card}>{childrenCard}</div>
      <div className={styles.cardInfoContainer}>{childrenInfo}</div>
    </div>
  );
};

import type {UseMutationResult, UseQueryResult} from "@tanstack/react-query/src/types";
import {GenericResponse} from "../types";
import {ERROR_STATUS, ERROR_STATUS_CODES, SUCCESS_STATUS_CODES} from "../utils/constants";

export const useHandleGenericResponse=<T, R>(
    reactQuery:
        | UseMutationResult<GenericResponse<R>, Error, T>
        | UseQueryResult<GenericResponse<R>, Error>
) => {
    const isSuccess = reactQuery?.data
        ? SUCCESS_STATUS_CODES.includes(reactQuery?.data?.status)
        : undefined;
    const isError = reactQuery?.data
        ? ERROR_STATUS_CODES.includes(reactQuery?.data?.status)
        : reactQuery.isError ?? undefined;
    const isErrorMessageExist =
        isError &&
        reactQuery?.data?.message?.messageType === ERROR_STATUS &&
        reactQuery?.data?.message?.messageContent !== null;
    const errorMessage = isErrorMessageExist
        ? reactQuery?.data?.message?.messageContent
        : reactQuery.error?.message ?? undefined;
    const data = reactQuery?.data?.body;
    const state = reactQuery?.data?.state ?? undefined;

    return { isSuccess, isError, isErrorMessageExist, errorMessage, data ,state };
};

import './shared/styles/App.css';
import {ForgetPassword, Login, SignUp} from './modules/auth/pages';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MainRoutes from './modules/portal/routes/MainRoutes';
import { useHandleTranslation } from './shared/hooks';
import { useEffect } from 'react';
import {OnboardingPage} from "./modules/portal/pages";
const App = () => {
  const { currLng } = useHandleTranslation();
  useEffect(() => {
    document.body.setAttribute('lang', currLng);
  }, [currLng]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000
      }
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path={"/onboarding"} element={<OnboardingPage/>}/>
          <Route path={'/signup'} element={<SignUp />} />
          <Route path={'/authentication'} element={<Login />} />
          <Route path={'/forgetpassword'} element={<ForgetPassword />} />

          <Route path={'/*'} element={<MainRoutes />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;

import { ApiJaxRsResponse } from "../../../../shared/types";

export const ESERVICE_LIST_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/localappmanager/getAllEservices",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
   }

export const ESERVICE_CREATION_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/localappmanager/createEservice",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
   }   
import { FC } from 'react';
import { CardProps } from '../card/Card.tsx';
import { HeaderTable, HeaderTableProps } from '../table-items/HeaderTable.tsx';
import { RowTable } from '../table-items/RowTable.tsx';
import styles from './table.module.css'
export interface TableProps {
  headerItems: HeaderTableProps;
  rows: CardProps[];
}
export const Table: FC<TableProps> = (props) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead className={styles.header}>
          <HeaderTable items={props.headerItems.items} />
        </thead>
        <tbody className={styles.body}>
          {props.rows.map((row: CardProps, index: number) => (
            <RowTable
              key={index}
              imageUrl={row.imageUrl}
              industry={row.industry}
              name={row.name}
              favorite={row.favorite}
              id={row.id}
              role={row.role}
              status={row.status}
              verification={row.verification}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

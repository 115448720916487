import React from 'react';
import styles from './document.module.css';
import DocumentImageViewer from './DocumentImageViewer';
import {Star} from "@phosphor-icons/react";

interface DocumentCardProps {
  documentImage: string;
  addedOn: string;
  totalPage: number;
  title: string;
  status: string;
  description: string;
  favorite?: boolean;
  documentLink?: string;
}

export const  DocumentCard: React.FC<DocumentCardProps> = (props) => {
const {
  documentImage,addedOn ,
  totalPage,
  title,
  status,
  description,
  favorite=false,
  documentLink}=props

  const onViewMoreClick = () => {
    console.log("View More clicked!");
    console.log(documentImage);
    console.log(documentLink);
  };
  const renderFavoriteIcon=()=>{
   if(favorite) return (<div className="flex items-center"><Star size={24} weight="fill" className="text-amber-400"  /></div>)
    return <div className="flex items-center invisible"><Star size={24} weight="fill" className="text-amber-400"/></div>
  }
  return (
      <div className={`${styles.documentWrapper} ${styles['portal-documents-document_card-css-var']}`}>
        <div className="flex gap-2">
          {renderFavoriteIcon()}
          <DocumentImageViewer documentImage={"https://picsum.photos/200/300"} />
        </div>
          <div className="flex flex-col justify-between gap-2 w-full">
            <div className="flex flex-col gao-3">
              <div className={styles.documentHeader}>
                <div className="flex gap-3">
                  <span className={styles.documentTitle}>{title}</span>
                  <span className={styles.statusBadge}>{status}</span>
                </div>
                <div className={styles.moreIcon} onClick={onViewMoreClick}>
                  <i className="material-flex-icon">more_vert</i>
                </div>
              </div>

              <div className={styles.documentContent}>
                <span>Ajouté le {addedOn}</span>
                <span>{description}</span>
                <span>{totalPage} {totalPage > 1 ? 'Pages' : 'Page'}</span>
              </div>
            </div>

            <div className={styles.documentActions}>
              <div className={styles.documentLink}>
                <span>Accéder au dossier source</span>
                <i className="material-flex-icon text-md">arrow_outward</i>
              </div>
            </div>


          </div>
      </div>
  );
};



import { CardProps } from "../components/ui/cards/OrganizationCard";

export const ESERVICE_WIDGET_MOCK_DATA: CardProps[] = [
    {
      id:'ayoooooooo',
      name: 'Inwi',
      industry: 'Telecom',
      status: 'Approuvé',
      verification: 'En attente d’approbation',
      favorite: true,
      imageUrl: '',
      isEservice: true,
      guichetName: 'souhaib',
      dateModif: 'hel',
      isKaraz: true,
      isPublic: false,
      lat: 0,
      lng: 0,
    },
    {
      id:'ayoooooooo',
      name: '710 Single Window',
      industry: 'Government',
      role: 'Administrateur',
      status: 'Approuvé',
      verification: 'Completed',
      favorite: false,
      imageUrl: '/pictures/example-air-royale.png',
      isEservice: true,
      guichetName: 'souhaib',
      dateModif: 'hel',
      isKaraz: false,
      isPublic: true,
      lat: 0,
      lng: 0
    },
    {
      id:'ayoooooooo',

      name: 'Microsoft',
      industry: 'Technology',
      role: 'Citizen developer',
      status: 'Approuvé',
      verification: 'Completed',
      favorite: true,
      imageUrl: '/pictures/example-air-royale.png',
      isEservice: true,
      guichetName: 'souhaib',
      dateModif: 'hel',
      lat: 0,
      lng: 0
    },
    {
      id:'ayoooooooo',

      name: 'OPEL Engines',
      industry: 'Automotive',
      role: 'Utilisateur',
      status: 'Approuvé',
      verification: 'Completed',
      favorite: false,
      imageUrl: '/pictures/example-air-royale.png',
      isEservice: true,
      guichetName: 'souhaib',
      dateModif: 'hel',
      lat: 0,
      lng: 0
    },
    {
      "id": "ce609307-96ce-45b3-a479-466ae2662d5d",
      "name": "Luella ",
      "guichetName": "Kuhic",
      "status": "Wiegand",
      "imageUrl": "/pictures/example-air-royale.png",
      "dateModif": "Fri Apr 26 2024 00:47:55 GMT+0100 (UTC+01:00)",
      "isEservice": false,
      "isPublic": true,
      "isKaraz": true,
      industry: "",
      lat: 0,
      lng: 0
    },
  {
    "id": "9a40c12f-da8e-400f-896d-6c7fcdfe8edb",
    "name": "Tierra ",
    "guichetName": "West",
    "status": "Herman",
    "imageUrl": "/pictures/example-air-royale.png",
    "dateModif": "Wed Aug 21 2024 21:58:49 GMT+0100 (UTC+01:00)",
    "isEservice": true,
    "isPublic": true,
    "isKaraz": true,
    industry: "",
    lat: 0,
    lng: 0
  },
]
   



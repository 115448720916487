import { FC, useState } from 'react';
import { Card } from '../Card';
import styles from '../cards.module.css';
import classNames from 'classnames';
import FavoriteSvg from '../../../../../../shared/components/icons/FavoriteSvg';
import { IconSize } from '../../../../../../shared/utils/enums';
import ThreePointsSvg from '../../../../../../shared/components/icons/ThreepointsSvg';
import { AdvancedDropDown } from 'kls-ui';

import logo from '../../../../assets/icons/logo-app.svg';
import OrdinateurSvg from '../../../../../../shared/components/icons/OrdinateurSvg';
import logoKaraz from '../../../../assets/icons/karaz-logo.svg';
import VerifiedSvg from '../../../../../../shared/components/icons/VerifiedSvg';
import TimeSvg from '../../../../../../shared/components/icons/TimeSVG';
export interface CardEserviceProps {
  eservice: Eservice;
  cardAction: CardActionProps;
  dropDownData?: Array<{
    [key: string]: string | undefined;
  }>;
}
export interface Eservice {
  id: string;
  name: string;
  industry: string;
  role: string;
  status: string;
  verification: string;
  favorite: boolean;
  imageUrl: string;
  guichetName: string;
  dateModif: string;
  isKaraz: boolean;
  isPublic: string;
}
interface CardActionProps {
  onSelectDropDown: (selection: { id: string; selectedValue: string }) => void;
}
export const CardEservice: FC<CardEserviceProps> = (props) => {
  const [toggleMoreActions, setToggleMoreActions] = useState<boolean>(false);
  const cardFavoriteStyle = classNames({
    [styles.starF]: props.eservice.favorite,
    [styles.starNF]: !props.eservice.favorite
  });
  const cardRoleStyle = classNames({
    [styles.cardRole]: true
  });
  const cardIsPublicStyle = classNames({
    [styles.cardTitleSvg]: props.eservice.isPublic,
    [styles.notPublic]: !props.eservice.isPublic
  });
  const handleSelect = (value: string) => {
    if (props.cardAction?.onSelectDropDown) {
      props.cardAction.onSelectDropDown({
        id: props.eservice.id,
        selectedValue: value
      });
      return;
    }
  };
  const renderFavoriteIcon = () => {
    return <FavoriteSvg className={cardFavoriteStyle} size={IconSize.PX18} />;
  };
  const renderStatusIcon = () => {
    if (props.eservice.isPublic != undefined) {
      return (
        <span className={`material-icon ${cardIsPublicStyle}`}>
          {props.eservice.isPublic ? 'visibility' : 'visibility_off'}
        </span>
      );
    }
    return null;
  };
  const renderDateIcon = () => {
    return props.eservice.dateModif ? (
      <TimeSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
    ) : null;
  };
  const renderVerificationIcon = () => {
    if (props.eservice.verification) {
      return (
        <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
      );
    }
    return null;
  };
  const renderDropDown = () => {
    if (toggleMoreActions) {
      return (
        <AdvancedDropDown
          isLunchAction={true}
          style={{
            top: '15px',
            minWidth: '100px',
            right: '0px',
            padding: '5px'
          }}
          closeWhenClickOutside={() => setToggleMoreActions(false)}
          handleChange={(c) => {
            setToggleMoreActions(false);
            handleSelect(c.value);
          }}
          enableSearch={false}
          data={props.dropDownData}
        />
      );
    }
    return null;
  };
  return (
    <Card
      onDbClick={() =>
        props.cardAction.onSelectDropDown({
          id: props.eservice.id,
          selectedValue: 'open'
        })
      }
      childrenCard={
        <>
          <div className={styles.starFContainer}>
            {renderFavoriteIcon()}
            <div
              onClick={() => setToggleMoreActions(!toggleMoreActions)}
              className={styles.threePointsContainer}>
              <ThreePointsSvg
                className={styles.threePoints}
                size={IconSize.SM}
              />
              {renderDropDown()}
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              src={props.eservice.imageUrl || logo}
            />
          </div>
          <div className={styles.industryContainer}>
            <div>
              {props.eservice.isKaraz && (
                <img className={styles.imageKaraz} src={logoKaraz} />
              )}
            </div>
            {props.eservice.role && (
              <span className={cardRoleStyle}>{props.eservice.role}</span>
            )}
            <span className={styles.industry}>
              <OrdinateurSvg size={IconSize.PX14} />
            </span>
          </div>
        </>
      }
      childrenInfo={
        <>
          <div className={styles.cardnameTime}>
            <span className={styles.cardTitle}>
              {props.eservice.name}
              {renderVerificationIcon()}
            </span>
            {renderDateIcon()}
            {props.eservice.status && (
              <>
                <span className={styles.publicCard}>
                  {props.eservice.status}
                  {renderStatusIcon()}
                </span>
              </>
            )}
          </div>
          <div>
            {props.eservice.guichetName && (
              <>
                <span className={styles.cardTitle}>
                  <span className={styles.cardProp}>Propriété de </span>
                  {props.eservice.guichetName}
                  <VerifiedSvg
                    size={IconSize.PX14}
                    className={styles.cardTitleSvg}
                  />
                </span>
              </>
            )}
          </div>
        </>
      }
    />
  );
};

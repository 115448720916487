import {FC} from "react";
import classNames from "classnames";

interface MaterialIconProps {
    label:string
    className?:string
    center?:boolean
}

export const MaterialIcon:FC<MaterialIconProps>=(props)=>{
    const {center=false}=props
  const {className=""}=props;
    const rootStyle=classNames({
        ["material-icon"]:!center,
        ["material-flex-icon"]:center,
        [className]:true
    })
    return <i className={rootStyle}>{props.label}</i>
}
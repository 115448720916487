import {List} from "../../list/List";
import {FC, ReactNode} from "react";
import {useHandleComponentVisibility} from "../../../hooks";
import classNames from "classnames";
import {ListItemType} from "../../../types";

interface MenuProps  {
    icon :ReactNode
    list?:ListItemType[];
    onChange:(selectedElements:string[])=>void
    size?:"sm"|"md",
    onClick?:()=>void
    className?:string,
}
export const Menu:FC<MenuProps>=(props)=>{
   const {className="",onClick=()=>{}}=props;
    const {isOpen,setOpenStatus,ref}=useHandleComponentVisibility()
    const handleIconClick=()=>{
       if (props.list) setOpenStatus(true);
        onClick();
    }
    const rootStyles=classNames({
        [className]:true,
        "relative w-fit":true
    })
    return (
    <div className={rootStyles}  ref={ref}>
        <div className="cursor-pointer" onClick={handleIconClick}>
            {props.icon}
        </div>
        {props.list && <List  list={props.list} onChange={props.onChange} open={isOpen} className="right-0 z-30" setOpenStatus={setOpenStatus}  />}
    </div>)
}

import { Eservice } from "../../components/ui/cards/CardComponents/CardEservice";
import { EserviceData } from "../../types/data.types";

export const convertToCardProps = (eservices: EserviceData[]): Eservice[] => {
  return eservices.map(eservice => ({
    id: eservice.idEservice,
    name: eservice.nameEservice,
    industry: eservice.Industrie, 
    role: eservice.role,
    status: eservice.status,
    verification: eservice.org_verification || "",
    favorite: eservice.favorite || false,
    imageUrl: eservice.imageEservice,
    guichetName: eservice.guichetName,
    dateModif: eservice.dateChangement,
    isKaraz: eservice.sourceCreation,
    isPublic: eservice.type_publication || ""
  }));
};

import { FC, useEffect, useRef, useCallback } from 'react';
import styles from './card-grid.module.css';
import {
  CARD_DROPDOWN_ALL,
  CARD_DROPDOWN_NO_ROLE
} from '../../../utils/constants/components.constants';
import { CardGuichet, Guichet } from '../cards/CardComponents/CardGuichet';
import { CardEservice, Eservice } from '../cards/CardComponents/CardEservice';
import SkeletonGrid from './SkeletonGrid';

export interface CardGridProps {
  items: Guichet[] | Eservice[];
  onSelectDropDown: (selection: { id: string; selectedValue: string }) => void;
  fetchMoreData: (page: number) => void;
  currentPage: number;
  totalPages: number;
  type: 'guichet' | 'eservice';
  isFetching?: boolean | false;
}

export const CardGrid: FC<CardGridProps> = ({
  items,
  onSelectDropDown,
  fetchMoreData,
  currentPage,
  totalPages,
  type,
  isFetching
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Get dropdown data based on role
  const getDropDownData = useCallback((role: string | undefined) => {
    if (!role) return CARD_DROPDOWN_NO_ROLE;
    return role === 'admin' || role === 'user'
      ? CARD_DROPDOWN_ALL
      : CARD_DROPDOWN_NO_ROLE;
  }, []);

  // Fetch more items when needed
  const fetchMoreItems = useCallback(() => {
    if (currentPage >= totalPages) return;
    fetchMoreData(currentPage + 1);
  }, [totalPages, fetchMoreData, currentPage]);

  // Handle scroll event
  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    if (scrollTop + clientHeight >= scrollHeight - 2) {
      fetchMoreItems();
    }
  }, [fetchMoreItems]);

  // Manage scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container && currentPage < totalPages) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container && currentPage < totalPages) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll, currentPage, totalPages]);

  return (
    <>
      <div className={styles.cardsContainer} ref={containerRef}>
        {items.map((item, index) => {
          if ('role' in item && type === 'guichet') {
            return (
              <CardGuichet
                key={index}
                guichet={item as Guichet}
                dropDownData={getDropDownData(item.role)}
                cardAction={{
                  onSelectDropDown: (selection) => onSelectDropDown(selection)
                }}
              />
            );
          } else {
            return (
              <CardEservice
                key={index}
                eservice={item as Eservice}
                dropDownData={getDropDownData(item.role)}
                cardAction={{
                  onSelectDropDown: (selection) => onSelectDropDown(selection)
                }}
              />
            );
          }
        })}
        {isFetching && <SkeletonGrid numberOfCards={6}/>}
      </div>
    </>
  );
};

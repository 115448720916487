import styles from './windowtaskitem.module.css';
import { Button } from 'kls-ui';
import {FC} from "react";

interface WindowTaskItemProps {
  image: string;
  title: string;
  description: string;
  onAccessGuichetClick: () => void;
}

export const WindowTaskItem: FC<WindowTaskItemProps> = ({
  image,
  title,
  description,
  onAccessGuichetClick,
}) => {

  return (
      <div className={`${styles.rootContainer} ${styles['kls-window-task-item-var']}`}>
          <div className={styles.contentContainer}>
            <img src={image} alt={title} className="h-[52px] w-[52px] rounded-full "/>
              <div className="">
                  <span className={styles.title}>{title}</span>
                  <p className={styles.description}>{description}</p>
              </div>
          </div>
          <div className="px-2">
              <Button
                  label="Accès guichet"
                  size="xs"
                  color="primary"
                  styling="ghost"
                  args={{onClick: onAccessGuichetClick}}
              />
          </div>
      </div>
  );
};


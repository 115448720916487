import { CardProps } from "../components/ui/cards/OrganizationCard";



export const GUICHET_WIDGET_MOCK_DATA: CardProps[] = [
  {
    id:'ayoooo',
    name: 'Inwi',
    industry: 'Telecom',
    role: 'User',
    status: 'Approuvé',
    verification: 'En attente d’approbation',
    favorite: true,
    imageUrl: '/pictures/example-air-royale.png',
    lat:33.43,
    lng: -7.29
  },
  {
    id:'ayoooo',
    name: '710 Single Window',
    industry: 'Government',
    role: 'Owner',
    status: 'Approuvé',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png',
    lat:31.99,
    lng: -6.46
  },
  {
    id:'ayoooo',

    name: 'Microsoft',
    industry: 'Technology',
    role: 'User',
    status: 'Approuvé',
    verification: 'Completed',
    favorite: true,
    imageUrl: '/pictures/example-air-royale.png',
    lat:30.64,
    lng: -8.70
  },
  {
    id:'ayoooo',

    name: 'OPEL Engines',
    industry: 'Automotive',
    role: 'User',
    status: 'Approuvé',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png',
    lat:35.07,
    lng: -5.36
  },
  {
    id:'ayoooo',

    name: '1337 Coding School',
    industry: 'Education',
    role: 'User',
    status: 'Approuvé',
    verification: 'En attente d’approbation',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png',
    lat:34.92,
    lng: -5.19
  },
  {
    id:'ayoooo',

    name: 'Multicast e-services shop',
    industry: 'Technology',
    role: 'Citizen Developer',
    status: 'En attente d’approbation',
    verification: 'In Progress',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png',
    lat:34.74,
    lng: -5.67
  },
  {
    id:'ayoooo',

    name: 'UM6P',
    industry: 'Education',
    role: 'User',
    status: 'Approuvé',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png',
    lat:31.02,
    lng: -8.04
  },
  {
    id:'ayoooo',

    name: 'OPEL SAV',
    industry: 'Automotive',
    role: 'User',
    status: 'Approuvé',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png',
    lat:35.21,
    lng: -4.31
  },
  {
    id:'ayoooo',

    name: 'Ribatis',
    industry: 'Technology',
    role: 'Owner',
    status: 'Approuvé',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png',
    lat:35.28,
    lng: -5.10
  },
  {
    id:'ayoooo',

    name: 'Royal Air Maroc',
    industry: 'Aviation',
    role: 'Administrator',
    status: 'Approuvé',
    verification: 'Completed',
    favorite: false,
    imageUrl: '/pictures/example-air-royale.png',
    lat:35.17,
    lng: -5.93
  }
];

export const filters = {
  industry: 'Technology',
  verification: 'Approuvé',
  role: 'User',
  status: 'Completed'
};

export const sortBy = 'Recent';



import { Guichet } from '../../components/ui/cards/CardComponents/CardGuichet';
import { AllGuichetsResponse } from '../../types/data.types';

export const convertToGuichetCard = (
  guichets: AllGuichetsResponse[]
): Guichet[] => {
  return guichets.map((guichet: AllGuichetsResponse) => ({
    id: guichet.organisationId,
    name: guichet.name,
    industry: guichet.industry || 'Unknown',
    role: guichet.role || 'User',
    status: guichet.status || 'Pending',
    verification: guichet.verification || 'Pending',
    favorite: guichet.favorite || false,
    imageUrl: guichet.logoGedId
      ? `https://portal.karaz.org/karazal/DownloadFile?gedId=${guichet.logoGedId}`
      : '/pictures/example-air-royale.png',
    changeTime: guichet.changeTime || ''
  }));
};

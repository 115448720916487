import React, { useState } from 'react';
import styles from './personae.module.css';
import PersonaeAvatar, { PersonaeAvatarProps } from './PersonaeAvatar';
import { Button } from 'kls-ui';
import { ButtonProps } from 'kls-ui/components/Button/Button';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

interface PersonaeCards {
  avatar?: PersonaeAvatarProps;
  title?: string;
  content?: string;
  buttonFirst: ButtonProps;
  buttonSecond: ButtonProps;
  checkboxLabel: string;
}

interface PersonaeProps {
  personae: PersonaeCards[];
}

export const Personae: React.FC<PersonaeProps> = ({ personae }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = personae.length;

  const goToNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };
  const LeftIcon = <ChevronLeftIcon className='size-4' />;
  const RightIcon = <ChevronRightIcon className='size-4' />;

  return (
    <div className={styles.personaeWrapper}>
      <div className={styles.personaeContainer}>
        {totalPages != 1 && <Button label='' leftIcon={LeftIcon} size='sm' color='primary' styling='outline' args={{ onClick: goToPreviousPage }} />}
        <div className={styles.carouselWrapper}>
          <div className={styles.carousel} style={{ transform: `translateX(-${currentPage * 100}%)` }}>
            {personae.map((persona, index) => (
              <div key={index} className={styles.personaeSlide}>
                <div className={styles.personaeAvatar}>
                  <PersonaeAvatar avatar={persona.avatar?.avatar} title={persona.avatar?.title} />

                </div>
                <div className={styles.personaeContent}>
                  <span className={styles.personaeTitle}>{persona.title}</span>
                  <p className={styles.description}>{persona.content}</p>
                  <div className={styles.personaeActions}>
                    <Button
                      leftIcon={persona.buttonFirst?.leftIcon}
                      rightIcon={persona.buttonFirst?.rightIcon}
                      label={persona.buttonFirst?.label}
                      size={persona.buttonFirst?.size}
                      color={persona.buttonFirst?.color}
                      styling={persona.buttonFirst?.styling}
                      args={{ onClick: persona.buttonFirst?.args?.onClick }}
                    />
                    <Button
                      leftIcon={persona.buttonSecond?.leftIcon}
                      rightIcon={persona.buttonSecond?.rightIcon}
                      label={persona.buttonSecond?.label}
                      size={persona.buttonSecond?.size}
                      color={persona.buttonSecond?.color}
                      styling={persona.buttonSecond?.styling}
                      args={{ onClick: persona.buttonSecond?.args?.onClick }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {totalPages != 1 && <Button label='' rightIcon={RightIcon} size='sm' color='primary' styling='outline' args={{ onClick: goToNextPage }} />}
      </div>
    </div>
  );
};


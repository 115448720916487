import { FC, useState } from 'react';
import { CardProps } from '../card/Card.tsx';
import { IconSize } from '../../../../../shared/utils/enums';
import FavoriteSvg from '../../../../../shared/components/icons/FavoriteSvg.tsx';
import styles from './table-items.module.css';
import VerifiedSvg from '../../../../../shared/components/icons/VerifiedSvg.tsx';
import classNames from 'classnames';
import { AdvancedDropDown } from 'kls-ui';
import logo from '../../../assets/icons/logo-app.svg';

export const RowTable: FC<CardProps> = (props) => {
  const iconStyle = () =>
    classNames({
      ['material-symbols-outlined']: true,
      [styles.moreIcon]: true
    });
    const [toggleMoreActions, setToggleMoreActions] = useState<boolean>(false);
  return (
    <tr key={props.id}>
      <td className={styles.fCaseContainer}>
        <div className={styles.fCase}>
          <span className="cursor-pointer">
            {props.favorite ? (
              <FavoriteSvg className={styles.starF} size={IconSize.PX18} />
            ) : (
              <FavoriteSvg className={styles.starNF} size={IconSize.PX18} />
            )}
          </span>
          <img
            src={props.imageUrl || logo}
            alt={`${props.name} logo`}
            className="w-8 h-8 rounded-full"
          />
        </div>
      </td>
      <td className={styles.nameCaseContainer}>
        <div className={styles.nameCase}>
          <span>{props.name}</span>
          {props.verification && (
            <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
          )}
        </div>
      </td>
      <td className={styles.aCaseContainer}>
        <div className={styles.aCase}>{props.industry}</div>
      </td>
      <td className={styles.aCaseContainer}>
        <div className={styles.aCase}>{props.role}</div>
      </td>
      <td className={styles.lCaseContainer}>
        <div className={styles.lCase}>
          {props.status === 'Approuvé' ? (
            <span className={styles.statutContainerV}>{props.status}</span>
          ) : (
            <span className={styles.statutContainerNV}>{props.status}</span>
          )}
          <div onClick={() => setToggleMoreActions(!toggleMoreActions)} className='relative'>
            <span className={iconStyle()}>more_vert</span>
            {toggleMoreActions && (
          <AdvancedDropDown
            isLunchAction={true}
            style={{ top: '15px', minWidth: '100px', right: '0px' }}
            closeWhenClickOutside={() => {
              setToggleMoreActions(!toggleMoreActions);
            }}
            handleChange={() => {
              setToggleMoreActions(!toggleMoreActions);
            }}
            enableSearch={false}
            data={[
              {
                label: 'Acceder',
                value: 'open',
                icon: 'arrow_outward',
                color: 'var(--blue-600)'
              }
            ]}
            ></AdvancedDropDown>
        )}
          </div>
        </div>
      </td>
    </tr>
  );
};

import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { TopMenu } from '../layouts/menu/TopMenu';
import SidebarMenu from '../layouts/menu/SidebarMenu';
import {
  useSwitcherItems,
  useTopMenuItems
} from '../layouts/menu-item/menuItems';
import { Organisation } from '../../../shared/types/components.type';
import styles from './main.module.css';
import classNames from 'classnames';
import { UserProfile } from '../../../shared/types';
import { getUserProfile } from '../../../shared/utils/helpers';

const organisation: Organisation = {
  organisationName: 'Royal Air Maroc',
  organisationImgPath: '/pictures/example-air-royale.png'
};
const notShow = ['create-window']
const Main = () => {
  const location = useLocation();
  const path = location.pathname.split('/').filter(Boolean)
  let guichetId: string =
      path.length >= 3 && !notShow.includes(path[2])
      ? path[3]
      : '';

  const user: UserProfile | undefined = getUserProfile();

  const [selectedRouteChildLabel, setSelectedRouteChildLabel] =
    useState<string>('');

  const [selectedRouteParentLabel, setSelectedRouteParentLabel] =
    useState<string>('');

  const switcherItemsData = useSwitcherItems();

  const topMenuItemsData = useTopMenuItems(
    location.pathname.split('/').filter(Boolean).length > 0
      ? location.pathname.split('/').filter(Boolean)[0]
      : 'guichet'
  );
  const handleSwitch = (option: string) => {
    setSelectedRouteParentLabel(option);
    setSelectedRouteChildLabel('Accueil');
  };

  const handleItemClick = (item: string) => {
    setSelectedRouteChildLabel(item);
  };

  const getSelectedDefault = (linkP: string, linkC: string): void => {
    const parentLabel =
      switcherItemsData.find((item) => item.link === linkP)?.label || 'Guichet';
    setSelectedRouteParentLabel(parentLabel);

    const childLabel =
      topMenuItemsData.find((item) => item.link === linkC)?.label || '';
    setSelectedRouteChildLabel(childLabel);
  };

  useEffect(() => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    switch (true) {
      case pathParts.length === 1:
        getSelectedDefault(pathParts[0], pathParts[0]);
        break;
      case pathParts.length >= 2:
        getSelectedDefault(pathParts[0], pathParts[0] + '/' + pathParts[1]);
        if (pathParts.length >= 3 && !notShow.includes(pathParts[2])) {
          guichetId = pathParts[2];
        }
        break;
    }
  }, [location]);
  const mainContainerStyle = classNames({
    [styles.mainContainer]: true,
    [styles['main-css-var']]: true
  });
 console.log(guichetId != '')
  return (
    <div className={mainContainerStyle}>
      <div className={styles.bannerContainer}></div>
      <div className={styles.mainContentContainer}>
        <TopMenu
          user={user}
          selected={selectedRouteParentLabel}
          selectedItem={selectedRouteChildLabel}
          switcherItems={switcherItemsData}
          topMenuItems={topMenuItemsData}
          handleSwitch={handleSwitch}
          handleItemClick={handleItemClick}
        />
        <div className={styles.mainBodyContainer}>
          {guichetId != '' && (
            <SidebarMenu organisation={organisation} />
          )}
          <div className={styles.mainOutletContainer}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;

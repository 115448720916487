import { FC, useEffect, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import '../map.css';
import { FilterBarItemSortByProps, FilterBarSortBy } from '../../../../modules/portal/components/ui/filter-bar/FilterBarSortBy';

interface FilterControlProps {
  items: FilterBarItemSortByProps[];
}
export const FilterControl: FC<FilterControlProps> = ({ items }) => {
  const map = useMap();
  const filterDivRef = useRef<HTMLDivElement | null>(null); // Reference to store filter div
  const [isFilterVisible, setFilterVisible] = useState(false); // To manage filter visibility

  useEffect(() => {
    const filterButton = L.control({ position: 'topleft' });
    filterButton.onAdd = function () {
      const div = L.DomUtil.create(
        'div',
        'leaflet-bar leaflet-control leaflet-control-custom'
      );
      div.innerHTML =
        '<span class="flex justify-center items-center w-full h-full"><svg fill="var(--mapping-slate-500)" height="16px" viewBox="0 0 12 12" width="16px" xmlns="http://www.w3.org/2000/svg"><path d="M2.66996 1.99996H9.33663L5.99663 6.19996L2.66996 1.99996ZM0.836626 1.73996C2.18329 3.46663 4.66996 6.66663 4.66996 6.66663V10.6666C4.66996 11.0333 4.96996 11.3333 5.33663 11.3333H6.66996C7.03663 11.3333 7.33663 11.0333 7.33663 10.6666V6.66663C7.33663 6.66663 9.81663 3.46663 11.1633 1.73996C11.5033 1.29996 11.19 0.666626 10.6366 0.666626H1.36329C0.809959 0.666626 0.496626 1.29996 0.836626 1.73996Z" /></svg></span>';
      div.style.backgroundColor = 'var(--mapping-slate-000)';
      div.style.border = '1px solid var(--mapping-slate-200)';
      div.style.width = '40px';
      div.style.height = '40px';
      div.style.borderRadius = '8px';
      div.style.cursor = 'pointer';
      // Add hover effect
      div.onmouseover = function () {
        div.style.border = '1px solid var(--mapping-slate-300)'; // Change border on hover
        div.style.transform = 'scale(1.05)';
      };

      div.onmouseout = function () {
        div.style.border = '1px solid var(--mapping-slate-200)'; // Reset border when not hovered
        div.style.transform = 'scale(1)';
      };
      div.onclick = function () {
        setFilterVisible((prevState) => !prevState);
      };

      return div;
    };
    if (filterButton) {
      filterButton.addTo(map);
    }

    return () => {
      map.removeControl(filterButton);
    };
  }, [map]);

  return (
    <>
      {isFilterVisible && (
        <div
          ref={filterDivRef}
          className="filter-container bgfilter-css-var"
          style={{
            fontFamily: 'Inter, sans-serif',
            position: 'absolute',
            left: '60px',
            top: '10px',
            padding: '10px',
            borderRadius: '8px',
            zIndex: 1000
          }}>
          <FilterBarSortBy items={items} />
        </div>
      )}
    </>
  );
};

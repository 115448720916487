import {object, string} from "yup";


export const QAStepSchema = object().shape({
    detection: string().required("Ce champ est obligatoire."),
    usage: string().required("Ce champ est obligatoire."),
    role: string().required("Ce champ est obligatoire.")
});
export const OrgCreationStepSchema = object().shape({
    name: string().required("Ce champ est obligatoire."),
    domain: string().required("Ce champ est obligatoire."),
    industrie: string().required("Ce champ est obligatoire.")
});

import {FC} from "react";
import styles from './e-sign-widget.module.css'
import classNames from "classnames";
import {Button} from "kls-ui";
import {Signature} from "@phosphor-icons/react";
import {AdvancedUploader, DropDownListButton} from "../../../../../../shared/components";

export const ESignWidget:FC=()=>{
    const rootStyle=classNames({
        [styles["portal-eparapheur-esign-widget-css-var"]]:true,
        [styles.rootContainer]:true
    })
    const DropDownButtonLeftIcon=<i className="material-icon">post_add</i>;
    const DropDownButtonRightIcon=<i className="material-icon">expand_more</i>;
    const menuList=[
        {label:"xxxx",value:"see_more"},
        {label: "yyyy",value:"hide"},
    ]
    return (
        <div className={rootStyle}>
            <div className="flex justify-end">
           <DropDownListButton
               buttonAttribute={{args:{},size:"sm",color:"primary",styling:"solid",label:"Ajouter un document prêt",leftIcon:DropDownButtonLeftIcon,rightIcon:DropDownButtonRightIcon}}
               menuAttributes={{list:menuList,onChange:(()=>{})}}/>
       </div>
            <AdvancedUploader uploaderClassName="min-h-[600px]" title={"Faites glisser ou déposez vos documents ici"}/>
            <div className="flex justify-center w-full">
                <Button size="sm" color="success" styling="solid" leftIcon={<Signature size={24} />} label="e-Signer" args={{}}/>
            </div>

    </div>)
}

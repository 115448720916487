import {InputText} from "../../input/input-text/InputText";
import {List} from "../../list/List";
import {useEvent, useHandleComponentVisibility} from "../../../hooks";
import {SelectProps} from "../../../types";
import {forwardRef, ForwardRefRenderFunction, ReactNode, useEffect, useState} from "react";
import classNames from "classnames";


 const ForwardedSelect:ForwardRefRenderFunction<HTMLInputElement,SelectProps>=(props,ref)=>{
    const {size="sm",withError=false,displayIcon=false}=props;
     const handleEvent=(e:Event)=>{
         if (props.onChange) props.onChange(e);
     }
     const [inputRef,emitEvent]=useEvent<HTMLInputElement>("input-select",handleEvent);
     const {isOpen,setOpenStatus,ref:visibilityRef}=useHandleComponentVisibility();
     const [inputValue,setInputValue]=useState<string>();
     const listSize=["sm","md"].includes(size) ? "sm" : "md" ;
     const [BeforeTextIcon,setBeforeTextIcon]=useState<ReactNode>()
     const getElementFromValue=(value:string)=>props.options.find((item)=>item.value===value);
     const open=()=>(setOpenStatus(true))

    const expandIconStyle=classNames({
        ["material-flex-icon text-base text-slate-500 cursor-pointer transition-all duration-300"]:true,
        ["rotate-180"]:isOpen
    })

    const ExpandIcon=<i className={expandIconStyle}  >expand_more</i>


     const handleListChange=(value:string[])=>{
        const selectedElement=getElementFromValue(value[0]);
        if (selectedElement) {
            setInputValue(selectedElement.label);
            setBeforeTextIcon(selectedElement.icon);
        }
    }
     useEffect(() => {
         if (inputRef.current && inputValue) {
             inputRef.current.value=inputValue;
             emitEvent();
         }
     }, [inputValue]);

     const getErrorMessage=props.errorMessage ?? "";
     const renderFooter=()=> {
         if(withError) return (<span className="text-red-600 text-xs">{getErrorMessage}</span>)
         return (<></>)
     }
     const footerStyles=classNames({
         ["transition-all duration-300"]:true,
         ["show"]:props.errorMessage,
         ["notShow"]:!props.errorMessage,
         ["h-3"]:withError
     })
    const renderBeforeTextIcon=()=>{
         if (displayIcon) return {beforeText:BeforeTextIcon}
         return (<></>)
    }
    return (
        <div ref={visibilityRef}>
            <input className="hidden" ref={inputRef}/>
            <InputText size={size}
                       ref={ref}
                       onClick={open}
                       label={props.label}
                       errorMessage={props.errorMessage}
                       placeholder={props.placeholder}
                       icon={ExpandIcon}
                       onlyRead
                       value={inputValue}
                       {...renderBeforeTextIcon()}
            />
            <div className="relative">
                <div className={footerStyles}>
                    {renderFooter()}
                </div>
                <List size={listSize} list={props.options} onChange={handleListChange} open={isOpen}
                      className="w-full z-50 top-1.5" setOpenStatus={setOpenStatus} preventCheck={props.preventCheck} closeAlways={props.alwaysClose}/>
            </div>
        </div>
    )
 }
export const Select = forwardRef(ForwardedSelect);
import {FC,} from 'react';
import { Link } from 'react-router-dom';
import styles from './menuItem.module.css';
import classNames from 'classnames';

import {MaterialIcon} from "../../../../shared/components/icons";
export interface SidebarMenuItemProps {
  selectedItem: string;
  handleClick: (item: string) => void;
  label: string;
  link: string;
  materialIconLabel:string;
  isOpen?: boolean;
  blockEnd?: boolean;
  isForDelete?: boolean;
}

const SidebarMenuItemStyle = (props: SidebarMenuItemProps) =>
  classNames({
    [styles.menuItem]: true,
    [styles.endBolck]: props.blockEnd,
    ['justify-center']: !props.isOpen,
    [styles.innerLeftBorder]:
      props.label === props.selectedItem && !props.isForDelete,
    [styles.innerLeftBorderSupp]:
      props.label === props.selectedItem && props.isForDelete,
    [styles.menuItemColor]:
      props.label !== props.selectedItem && !props.isForDelete
  });


const linkStyle = (props: SidebarMenuItemProps) => {
  if (props.isForDelete) return { color: 'var(--red-600)' };
  return;
};

const SidebarMenuItem: FC<SidebarMenuItemProps> = (props) => {
    const IconStyle=classNames({
       ["text-base"] : true,
       ["text-slate-400"]:!(props.isForDelete && props.selectedItem),
       ["!text-red-600"] : props.isForDelete,
       ["text-blue-600"]: props.selectedItem
    })

  return (
    <Link
      onClick={() => props.handleClick(props.label)}
      style={linkStyle(props)}
      className={SidebarMenuItemStyle(props)}
      to={props.link}
    >
      <MaterialIcon className={IconStyle} label={props.materialIconLabel}/>
      {props.isOpen && props.label}
    </Link>
  );
};
export default SidebarMenuItem;

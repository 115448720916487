import {FC, ReactNode} from "react";
import styles from "./empty-data.module.css";
import { Button, ButtonProps } from "kls-ui";

interface EmptyData{
    icon?:ReactNode;
    title?:string;
    description:string;
    firstBtnArgs?: ButtonProps;
    secondBtnArgs?: ButtonProps;
}
export const EmptyData:FC<EmptyData>=(props)=>{
  const {
         icon=(<></>),
         title=""
      } = props;
    return (
        <div className={styles.noItemFound}>
       {icon}
        <span className={styles.title}>{title}</span>
      <span className={styles.description}>{props.description}</span>     
      {
          <>
              { props.firstBtnArgs && !props.secondBtnArgs && <Button {...props.firstBtnArgs} size="sm" />}
              {props.firstBtnArgs && props.secondBtnArgs && <div className="flex gap-5"><Button {...props.firstBtnArgs} size="sm" /> <Button {...props.secondBtnArgs} size="sm" /></div>}

         </>
      }

      </div>
     
    )
}

import {GUICHET_WIDGET_MOCK_DATA} from "../../../mockdata/MockDataOrganizations";
import {Section,OrganizationCard,OrganizationAddCard} from "../../../components/ui";

export const FavoriteWindowWidget=()=> {
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    return (
        <Section title="Guichet favoris / fréquemment visités" materialIconLabel="corporate_fare" menuList={menuList}>
            <div>
                <div className="grid grid-cols-3 gap-2">
                    {GUICHET_WIDGET_MOCK_DATA.map((element, index) => (<OrganizationCard key={index} {...element} />))}
                    <OrganizationAddCard onClick={() => {
                    }}/>
                </div>
            </div>

        </Section>
    )
}

import {Personae} from "../../components/ui";
import {HOME_PAGE_PROFILES} from "../../utils/constants";

import {
    FavoriteEserviceWidget,
    FavoriteWindowWidget,
    GameScopeWidget,
    TrainingProgramWidget,
    WindowPendingActionsWidget
} from "../widgets";


export const AccueilPage = () => {

    return (
        <div className="flex flex-col gap-3">
            <Personae personae={HOME_PAGE_PROFILES}/>
            <div className="flex gap-3">
               <WindowPendingActionsWidget/>
                <GameScopeWidget/>
            </div>
            <div className="flex gap-2">
               <FavoriteWindowWidget/>
               <FavoriteEserviceWidget/>
            </div>
            <TrainingProgramWidget/>
        </div>
    )
}

import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.markercluster';
import { FC, useEffect } from 'react';
import { CardProps } from '../../../../modules/portal/components/card/Card';
import '../map.css';
interface MarkerClusterContainerProps {
  markersData: CardProps[];
}

export const MarkerClusterContainer: FC<MarkerClusterContainerProps> = ({
  markersData
}) => {
  const createClusterCustomIcon = (cluster: L.MarkerCluster) => {
    const count = cluster.getChildCount();
    let size = 'large';

    if (count < 10) {
      size = 'small';
    } else if (count < 20) {
      size = 'medium';
    }

    return L.divIcon({
      html: `<div class="marker-cluster marker-cluster-${size}"><span>${count}</span></div>`,
      className: 'custom-marker-cluster',
      iconSize: L.point(40, 40, true)
    });
  };

  const map = useMap();

  useEffect(() => {
    const markerClusterGroup = L.markerClusterGroup({
      iconCreateFunction: createClusterCustomIcon
    });
    const customIcon = L.icon({
      iconUrl: '/pictures/marker-logo.png',
      iconSize: [32, 32],
      iconAnchor: [16, 16],
      popupAnchor: [0, -8]
    });
    markersData.forEach((markerData:CardProps) => {
      const marker = L.marker([markerData.lat, markerData.lng], {
        
        icon: customIcon,
        title: markerData.name
      })
        .bindPopup(`<div class="flex flex-col font-inter text-xs gap-2 w-100">
      <div class="flex justify-start items-center gap-2 w-full font-bold">
        <img class="w-7 h-7 rounded-full" src="${markerData.imageUrl}" />
        ${markerData.name}
      </div>
      <div class="flex justify-start gap-1 items-center">
        Propriété de <span class="font-bold">${markerData.industry}</span>
      </div>
      <div class='flex justify-between items-center'>
        <span>0 dossiers</span>
        <span class="flex gap-1 font-medium" style="color:var(--blue-500);cursor:pointer;"><span class="material-icon">arrow_outward</span>Accéder</span>
      </div>
    </div>`);
      markerClusterGroup.addLayer(marker);
    });

    map.addLayer(markerClusterGroup);

    return () => {
      map.removeLayer(markerClusterGroup);
    };
  }, [map, markersData]);

  return null;
};

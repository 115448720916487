import { FC, useState } from 'react';
import FavoriteSvg from '../../../../../../shared/components/icons/FavoriteSvg';
import { IconSize } from '../../../../../../shared/utils/enums';
import { Card } from '../Card';
import styles from '../cards.module.css';
import classNames from 'classnames';
import ThreePointsSvg from '../../../../../../shared/components/icons/ThreepointsSvg';
import OrdinateurSvg from '../../../../../../shared/components/icons/OrdinateurSvg';
import VerifiedSvg from '../../../../../../shared/components/icons/VerifiedSvg';
import logo from '../../../../assets/icons/logo-app.svg';
import { AdvancedDropDown } from 'kls-ui';
export interface CardGuichetProps {
  guichet: Guichet;
  cardAction: CardActionProps;
  dropDownData?: Array<{
    [key: string]: string | undefined;
  }>;
}
export interface Guichet {
  id: string;
  name: string;
  industry: string;
  role: string;
  status: string;
  verification: string;
  favorite: boolean;
  imageUrl: string;
  changeTime: string;
}
interface CardActionProps {
  onSelectDropDown: (selection: { id: string; selectedValue: string }) => void;
}
export const CardGuichet: FC<CardGuichetProps> = (props) => {
  const [toggleMoreActions, setToggleMoreActions] = useState<boolean>(false);
  const cardFavoriteStyle = classNames({
    [styles.starF]: props.guichet.favorite,
    [styles.starNF]: !props.guichet.favorite
  });
  const cardRoleStyle = classNames({
    [styles.cardRole]: true
  });
  const handleSelect = (value: string) => {
    if (props.cardAction?.onSelectDropDown) {
      props.cardAction.onSelectDropDown({
        id: props.guichet.id,
        selectedValue: value
      });
      return;
    }
  };
  const renderFavoriteIcon = () => {
    return <FavoriteSvg className={cardFavoriteStyle} size={IconSize.PX18} />;
  };
  const renderVerificationIcon = () => {
    if (props.guichet.verification) {
      return (
        <VerifiedSvg size={IconSize.PX14} className={styles.cardTitleSvg} />
      );
    }
    return null;
  };
  const renderDropDown = () => {
    if (toggleMoreActions) {
      return (
        <AdvancedDropDown
          isLunchAction={true}
          style={{
            top: '15px',
            minWidth: '100px',
            right: '0px',
            padding: '5px'
          }}
          closeWhenClickOutside={() => setToggleMoreActions(false)}
          handleChange={(c) => {
            setToggleMoreActions(false);
            handleSelect(c.value);
          }}
          enableSearch={false}
          data={props.dropDownData}
        />
      );
    }
    return null;
  };
  return (
    <Card
      onDbClick={() =>
        props.cardAction.onSelectDropDown({
          id: props.guichet.id,
          selectedValue: 'open'
        })
      }
      childrenCard={
        <>
          <div className={styles.starFContainer}>
            {renderFavoriteIcon()}
            <div
              onClick={() => setToggleMoreActions(!toggleMoreActions)}
              className={styles.threePointsContainer}>
              <ThreePointsSvg
                className={styles.threePoints}
                size={IconSize.SM}
              />
              {renderDropDown()}
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              src={props.guichet.imageUrl || logo}
            />
          </div>
          <div className={styles.industryContainer}>
            <div></div>
            {props.guichet.role && (
              <span className={cardRoleStyle}>{props.guichet.role}</span>
            )}
            <span className={styles.industry}>
              <OrdinateurSvg size={IconSize.PX14} />
            </span>
          </div>
        </>
      }
      childrenInfo={
        <>
          <div className={styles.cardnameTime}>
            <span className={styles.cardTitle}>
              {props.guichet.name}
              {renderVerificationIcon()}
            </span>
          </div>
        </>
      }
    />
  );
};

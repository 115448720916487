import { FocusEvent, forwardRef, ForwardRefRenderFunction, useState} from "react";
import styles from "./input-text.module.css"
import classNames from "classnames";
import {InputTextProps} from "../../../types";


 const ForwardedInputText:ForwardRefRenderFunction<HTMLInputElement,InputTextProps>=(props,ref)=>{
    const {
        icon,
        size="sm",
        label,
        withError=false,
        disabled=false,
        onlyRead=false,
        onClick,
        errorMessage,
        beforeText=(<></>),
        afterText={label:"",color:"black"},
        ...inputProps}=props;

    const [isFocused,setIsFocused]=useState<boolean>();
    const getErrorMessage=errorMessage ?? "";


    const rootStyle=classNames({
        [styles.rootContainer]:true,
        [styles["portal-input-input_text-css-var"]]:true,
    })
     const inputContainerStyle=classNames({
         [styles.inputContainer]:true,
         [styles.focusedInput]:isFocused,
         [styles.disabledInput]:disabled,
         [styles.readOnlyInput]:inputProps.readOnly,
         [styles.onlyReadInput]:onlyRead,
         [styles.errorInput]:props.errorMessage,
         ["h-8 px-2"]:size==="sm",
         ["h-9 px-2.5"]:size==="md",
         ["h-9 px-3"]:size==="lg",
     })
     const inputStyle=classNames({
         ["text-xs"]:size==="sm",
         ["text-[13px]"]:["lg","md"].includes(size),
         ["!cursor-pointer"]:onlyRead,
         [styles.input]:true,
     })
     const inputLabelStyle=classNames({
         [styles.inputLabel]:true,
         ["text-xs"]:size==="sm",
         ["text-sm"]:["lg","md"].includes(size),
     })
     const footerStyles=classNames({
         ["transition-all duration-300 "]:true,
         ["show"]:errorMessage,
         ["notShow"]:!errorMessage,
         ["h-3"]:withError
     })

     const handleInputBlur=(e:FocusEvent<HTMLInputElement>)=>{
         setIsFocused(false);
         inputProps.onBlur?.(e)
     }
     const handleInputFocus=(e:FocusEvent<HTMLInputElement>)=>{
        setIsFocused(true);
        inputProps.onFocus?.(e)
     }


    const renderLabel=()=>{
        if (label) return (
            <div className="flex justify-between w-full ">
            <span className={inputLabelStyle}>{label}</span>
             <span></span>
            </div>)
    }
    const renderIcon=()=>{
        return <>{icon}</>
    }
    const renderFooter=()=> {
        if(withError) return (<span className="text-red-600 text-xs">{getErrorMessage}</span>)
        if (props.helpText) return (<span>{props.helpText}</span>)
        return (<></>)
         }


    return (
        <div className={rootStyle}>
            {renderLabel()}
            <div className="w-full">
            <div className={inputContainerStyle} onClick={onClick}>
                <div className=" w-full flex gap-2">
                    {beforeText}
                    <input
                        ref={ref}
                        className={inputStyle}
                        readOnly={onlyRead}
                        {...inputProps}
                        onBlur={handleInputBlur}
                        onFocus={handleInputFocus}
                    />
                </div>
                <div className="flex gap-2 items-center">
                    <span className="text-[13px]" style={{color: afterText.color}}>{afterText.label}</span>
                    {renderIcon()}
                </div>

            </div>
                <div className={footerStyles}>
                {renderFooter()}
            </div>
            </div>
    </div>
    )
}
export const InputText= forwardRef(ForwardedInputText)
import {Section} from "../../../components/ui";
import {ESERVICE_WIDGET_MOCK_DATA} from "../../../mockdata/MockDataEservice";
import {OrganizationCard} from "../../../components/ui";
import {OrganizationAddCard} from "../../../components/ui";

export const FavoriteEserviceWidget=()=>{
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    return (
        <Section title="e-Services favoris / fréquemment visités" materialIconLabel="grid_view" menuList={menuList}>
            <div>
                <div className="grid grid-cols-3 gap-2">
                    {ESERVICE_WIDGET_MOCK_DATA.map((element, index) => (<OrganizationCard key={index} {...element} />))}
                    <OrganizationAddCard onClick={() => {
                    }}/>
                </div>
            </div>
        </Section>
    )
}
import {FC} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './menuItem.module.css';
import {MaterialIcon} from "../../../../shared/components/icons";
export interface SwitcherItemProps {
  selectedItem: string;
  handleClick: (item: string) => void;
  label: string;
  link: string;
  materialIconLabel:string;
}
const SwitcherItemStyle = (props: SwitcherItemProps) =>
  classNames({
    [styles.switchItem]: true,
    ['bg-blue-600 text-white']: props.label === props.selectedItem,
    ['text-gray-500']: props.label !== props.selectedItem
  });
const SwitcherItem: FC<SwitcherItemProps> = (props) => {
  return (
    <Link
      to={props.link}
      className={SwitcherItemStyle(props)}
      onClick={() => props.handleClick(props.label)}
    >
      <MaterialIcon className="text-base" label={props.materialIconLabel}/>
      {props.label}
    </Link>
  );
};

export default SwitcherItem;

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationCard, SimpleCard } from "../../../../../../shared/components";
import { OrganisationIcon } from "../../../icons";
import { Select, InputText } from "../../../../../../shared/components/controlled-ui";
import styles from "./steps.module.css";
import { Button } from "kls-ui";
import { ImageUploader } from "../image-uploader/ImageUploader";
import { StepsHeader } from "../layout/StepsHeader";
import classNames from "classnames";
import { NotificationType } from "../../../../../../shared/utils/enums";
import {
    OnboardingGuichetCreationForm,
    OnboardingGuichetCreationImageUploadData,
    OnboardingGuichetCreationRequest,
} from "../../../../types";
import { FC, useCallback, useEffect, useState } from "react";
import { OrgCreationStepSchema } from "../../../../holders/schemas";
import { DynamicListData } from "../../../../../../shared/types";
import {useHandleTranslation} from "../../../../../../shared/hooks";
import {removePrefixFromBase64, transformExtension} from "../../../../../../shared/utils/mapper";
import {RadioChangeEvent} from 'antd';
import {Radio, RadioGroup} from "../../../../../../shared/components/antd";
interface ApiData {
    industriesList: DynamicListData[] | undefined;
}
interface ApiStatus {
    industriesList: boolean | undefined;
    postCreateOrganization: boolean | undefined;
}
interface OrgCreationStepProps {
    rootClassName?: string;
    onSubmit: (data: OnboardingGuichetCreationRequest) => void;
    submitError: string | undefined;
    backButton:{label:string,onClick:() => void}
    isLoading: ApiStatus;
    isSuccess:ApiStatus
    data: ApiData;
}

export const OrgCreationStep: FC<OrgCreationStepProps> = (props) => {
    const { rootClassName = "" } = props;
    const { t:tO } = useHandleTranslation("window");

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<OnboardingGuichetCreationForm>({
        resolver: yupResolver(OrgCreationStepSchema)
    });

    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
    const [imageData, setImageData] = useState<OnboardingGuichetCreationImageUploadData | undefined>();
    const [widowType,setWindowType]=useState<"Guichet"|"e-Shop">("Guichet");
    const errorMessageStyles = classNames({
        ["!my-4"]: true,
        ['notShow']: !isErrorDisplayed,
        ['show transition-all duration-500']: isErrorDisplayed
    });

    const cardStyle = classNames({
        [rootClassName]: true,
        ["w-1/2"]: true,
    });

    const rootStyle = classNames({
        ["flex flex-col py-2"]: true,
        [styles["onboarding-steps-css-var"]]: true
    });

    const handleImageUploaderChange = useCallback(
        (image: string | undefined, error: string | undefined, format: string | undefined) => {
            setIsErrorDisplayed(true);
            setErrorMessage(error);
            if (image && format) {
                setImageData({ logoFormat: transformExtension(format), logoAsBase64: removePrefixFromBase64(image) });
                setIsErrorDisplayed(false);
            } else setImageData(undefined);
        },
        [errorMessage, imageData]
    );

    const handleSubmitStep = (formData: OnboardingGuichetCreationForm) => {
        if (imageData) props.onSubmit({ ...formData,...imageData,categorie_guichet:widowType,type:"Organisation"});
         else props.onSubmit({ ...formData,categorie_guichet:widowType,type:"Organisation"});
    };

    useEffect(() => {
        if (props.submitError) {
            setErrorMessage(props.submitError);
            setIsErrorDisplayed(true);
        } else {
            setIsErrorDisplayed(false);
        }
    }, [props.submitError]);

    const renderIndustriesOptions=()=>{
       return  props
           .data
           .industriesList
           ?.map((item: DynamicListData) => (
               {
               ...item,
               icon:<i className="material-flex-icon text-lg text-blue-600">{item.icon}</i>
               }))
    }
    const handleWindowTypeChange=(e:RadioChangeEvent)=>{
        setWindowType(e.target.value);
    }

    return (
        <SimpleCard spin={props.isLoading.postCreateOrganization} className={cardStyle}>
            <div className={rootStyle}>
                <StepsHeader
                    icon={<OrganisationIcon className="size-12" />}
                    title={tO("orgCreation_step_title")}
                    description={tO("orgCreation_step_description")}
                />
                <NotificationCard
                    type={NotificationType.ERROR}
                    className={errorMessageStyles}>
                    {errorMessage}
                </NotificationCard>
                <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-3">
                        <ImageUploader onChange={handleImageUploaderChange} />
                        <RadioGroup className="flex justify-center w-full py-2" onChange={handleWindowTypeChange} value={widowType}>
                            <Radio value={"Guichet"} label="Guichet"/>
                            <Radio value={"e-Shop"} label="e-Shop"/>
                        </RadioGroup>
                        <div className="flex flex-col py-3 gap-2">
                            <InputText
                                size="md"
                                label={tO('orgCreation_name_label')}
                                control={control}
                                name={'name'}
                                placeholder={tO("orgCreation_name_placeholder") }
                                error={errors.name?.message}
                                withError
                            />
                                <Select
                                    control={control}
                                    error={errors.industrie?.message}
                                    placeholder="Chosissez un élément"
                                    size="lg"
                                    label={tO("orgCreation_industry_label")}
                                    name="industrie"
                                    showSelectedIcon
                                    options={renderIndustriesOptions()}
                                    displayIcon
                                />

                            <InputText
                                size="lg"
                                label={tO("orgCreation_domain_label")}
                                control={control}
                                name={'domain'}
                                placeholder={tO("orgCreation_domain_placeholder") }
                                error={errors.domain?.message}
                                afterText={{label:"@karaz.org",color:"var(--mapping-slate-800)"}}
                                withError
                            />
                        </div>
                    </div>
                    <div className={styles.actionButton}>
                        <Button
                            label={props.backButton.label}
                            disabled={true}
                            color="neutral"
                            size="sm"
                            styling="outline"
                            args={{ onClick: props.backButton.onClick }}
                        />
                        <Button
                            label={tO("orgCreation_submit_button")}
                            color="primary"
                            size="sm"
                            styling="solid"
                            args={{ onClick: handleSubmit(handleSubmitStep) }}
                        />
                    </div>
                </div>
            </div>
        </SimpleCard>
    );
};

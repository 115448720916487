import {FC, ReactNode} from "react";
import classNames from "classnames";
import styles from "./page-content-container.module.css"
interface  PageContentCardProps {
    className?:string;
    children: ReactNode;
    slate?:boolean
}
export const PageContentContainer:FC<PageContentCardProps>=(props)=>{
    const {className="",slate=false}=props
    const rootStyle=classNames({
        [className]:true,
        ["w-full rounded-md h-[85vh]"]:true,
        [styles.defaultContainer]:true,
        [styles.slateContainer]:slate,
        [styles["page-content-card-css-var"]]:true
    })
    return (
        <div className={rootStyle}>
            {props.children}
        </div>
)
}
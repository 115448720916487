import { FC } from 'react';
import { MarkerPositionPopUpProps } from './MarkerPositionPopUp';

export const MarkerPositionPopUpContent: FC<MarkerPositionPopUpProps> = ({
  position,
  setPosition
}) => {
  return (
    <div className="popup-container">
      <span className="popup-coordinates">
        {position.lat.toFixed(2)} {position.lng.toFixed(2)}
      </span>
      <span onClick={setPosition} className="popup-button">
        <span className="material-icon">check</span>Choisir
      </span>
    </div>
  );
};

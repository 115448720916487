import {EarnedBadges, Section, StatsNumbers} from "../../../components/ui";

export const GameScopeWidget=()=>{
    const POINT_ACCUMULATED_GAMESCOPE=[
        {label:"+0",color:"#2563EB",icon:<i className="material-flex-icon">show_chart</i>},
        {label: "36",color:"#059669", icon:<i className="material-flex-icon">corporate_fare</i>},
        {label: "12",color:"#DC2626", icon:<i className="material-flex-icon">grid_view</i>}]

    const MONETARY_ECONOMIC_VALUE=[
        {label:"+743",color:"#2563EB",icon:<i className="material-flex-icon">show_chart</i>},
        {label: "21",color:"#059669", icon:<i className="material-flex-icon">corporate_fare</i>},
        {label: "27",color:"#DC2626", icon:<i className="material-flex-icon">grid_view</i>}]

    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    return (
        <Section title={"Gamescope"} materialIconLabel="sports_esports" className="flex flex-col justify-center gap-4" menuList={menuList}>
            <div className="flex gap-4">
                <Section nested title="Points cumulés" materialIconLabel="workspace_premium" iconBackgroundColor="#047857" >
                    <StatsNumbers mainNumber={{text:"44" ,color:"#059669"}} statisticsItems={POINT_ACCUMULATED_GAMESCOPE}/>
                </Section>
                <Section nested title="Valeur monétaire estimative" materialIconLabel="paid" iconBackgroundColor="#A16207" >
                    <StatsNumbers mainNumber={{text:"5.383 MAD",color:"#CA8A04"}} statisticsItems={MONETARY_ECONOMIC_VALUE}/>
                </Section>
            </div>

            <Section nested title="Badges gangnés" materialIconLabel="military_tech" className="flex justify-center">
                <EarnedBadges/>
            </Section>
        </Section>
    )
}
import {
    DynamicListData,
    GenericResponse,
    GenericResponseWrapper
} from "../../../../shared/types";
import {HttpMethods} from "../../../../shared/utils/enums";
import {
    INDUSTRIES_LIST_API_PATH,
    QUESTIONS_OPTION_API_PATH,
    SUBMIT_QA_API_PATH
} from "./apiPath";
import {OnboardingQARequest, OnboardingQAResponse} from "../../types";
import { fetchGenericJaxRsRequestFromFormData } from "../../../../shared/utils/helpers";


export const getOnboardingQuestions=():Promise<GenericResponse<OnboardingQAResponse>> =>{
    return fetchGenericJaxRsRequestFromFormData(undefined,QUESTIONS_OPTION_API_PATH,HttpMethods.POST);
}
export const getIndustriesList=():Promise<GenericResponse<DynamicListData[]>> =>{
    return fetchGenericJaxRsRequestFromFormData(undefined,INDUSTRIES_LIST_API_PATH,HttpMethods.POST);
}
export const postOnboardingQA=(data:OnboardingQARequest):Promise<GenericResponseWrapper<OnboardingQAResponse>> =>{
    return fetchGenericJaxRsRequestFromFormData(data,SUBMIT_QA_API_PATH,HttpMethods.POST);
}



import {Section,DocumentUpload,DocumentCard} from "../../../components/ui";

export const DocumentsWidget=()=>{
    const documentCardData =  [
        {
            documentImage: "Annual_Report_2023.pdf",
            addedOn: "2024-08-15",
            totalPage: 45,
            title: "Annual Report 2023",
            status: "Completed",
            description: "A comprehensive overview of the company's performance in 2023.",
            favorite: true,
            documentLink: "https://example.com/Annual_Report_2023.pdf",
        },
        {
            documentImage: "Project_Plan_Q4.docx",
            addedOn: "2024-09-10",
            totalPage: 30,
            title: "Project Plan Q4",
            status: "In Progress",
            description: "Detailed project plan for the fourth quarter of 2024.",
        },
        {
            documentImage: "Financial_Summary_2023.xlsx",
            addedOn: "2024-07-30",
            totalPage: 10,
            title: "Financial Summary 2023",
            status: "Pending Review",
            description: "Summary of the financial status for the year 2023.",
        },
        {
            documentImage: "Project_Plan_Q4.docx",
            addedOn: "2024-09-10",
            totalPage: 30,
            title: "Project Plan Q4",
            status: "In Progress",
            description: "Detailed project plan for the fourth quarter of 2024.",
        },
        {
            documentImage: "Financial_Summary_2023.xlsx",
            addedOn: "2024-07-30",
            totalPage: 10,
            title: "Financial Summary 2023",
            status: "Pending Review",
            description: "Summary of the financial status for the year 2023.",
        }
    ]
    const menuList=[
        {label:"Voir plus",value:"see_more",icon:<i className="material-flex-icon text-red-400">arrow_outward</i>},
        {label: "Masquer",value:"hide",icon:<i className="material-flex-icon text-red-400">delete</i>},
    ]
    return (
        <Section title="Mes documents" materialIconLabel={"description"} menuList={menuList}>
        <div className="grid grid-cols-2 gap-2">
            {documentCardData.map((element, index) => (<DocumentCard key={index} {...element}/>))}
            <DocumentUpload onFilesUploaded={() => {
            }}/>
        </div>
    </Section>)
}
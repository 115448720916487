import { Button} from "kls-ui";
import {ButtonProps} from "kls-ui";
import {FC,} from "react";
import {List} from "../../list/List.tsx";
import {ListItemType} from "../../../types";
import {useHandleComponentVisibility} from "../../../hooks";

interface ListProps{
    list:ListItemType[];
    multiple?:boolean,
    onChange:(selectedElements:string[])=>void
    size?:"sm"|"md",
    preventCheck?:boolean
    closeAlways?:boolean,
}
interface DropDownListButtonProps {
    buttonAttribute:ButtonProps;
    menuAttributes:ListProps;
}
export const DropDownListButton:FC<DropDownListButtonProps>=(props)=>{
 const {isOpen,setOpenStatus,ref}= useHandleComponentVisibility();
  const open=()=>setOpenStatus(true)
   return (
       <div className="relative" ref={ref}>
       <Button
           {...props.buttonAttribute}
           args={{...props.buttonAttribute.args,onClick:open}}
       />
           {props.menuAttributes && <List list={props.menuAttributes.list} onChange={props.menuAttributes.onChange} open={isOpen} className="right-0 my-1  w-full  z-30" setOpenStatus={setOpenStatus}  />}
        </div>
    )
}